:root {
  --icon-sizing: 2.25vw;
  --hover-color: #e2522b;
  --default-color: white;
}

.navbar-logo {
  height: 3.2552083333333335vw;
  width: 15vw;
  object-fit: contain;
}

.navbar-right {
  display: flex;
  gap: 2.1484375vw; /* 33px */
}

.koolio-navbar {
  height: max-content;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6vw;
  padding-right: 6vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.Container_search_in_koolio {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_in_koolio {
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 1.953125vw;
  text-align: left;
  padding-left: 0.6510416666666666vw;
  color: white;
  display: block;
  width: 13.194205729166669vw;
  font-size: 1.1067708333333333vw;
}

.search_icon:focus,
.notification_icon:focus,
.user-profile:focus {
  outline: none;
}

.search_icon:hover,
.notification_icon:hover,
.user-profile:hover {
  color: var(--hover-color);
}

.search_icon {
  height: var(--icon-sizing);
  width: var(--icon-sizing);
  color: var(--default-color);
  cursor: pointer;
  transition: color 0.3s;
  z-index: 10;
}

.Container_notification_in_koolio {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_icon {
  height: var(--icon-sizing);
  width: var(--icon-sizing);
  cursor: pointer;
  transition: color 0.3s;
  anchor-name: --notification-icon;
  z-index: 10;
}

.notification_icon_inactive {
  color: var(--default-color);
}

.notification_icon_active {
  color: blueviolet;
}

.pending_notification {
  font-size: 1vw;
  color: var(--default-color);
  display: none;
}

.notification {
  height: 22.2216796875vw;
  width: 18.0546875vw;
  border-radius: 0.9765625vw;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  opacity: 0.9;
  display: none;
  position: fixed;
  position-anchor: --notification-icon;
  top: calc(anchor(bottom) + 1vw);
  right: calc(anchor(right) - 4.5vw);
}

.notification-list {
  height: inherit;
  width: inherit;
  border-radius: 0.9765625vw;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  color: white;
  padding: 1.041vw; /* 16px */
  display: flex;
  flex-direction: column;
  gap: 1vw;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.notification-content {
  font-size: 1vw;
}

.user-profile {
  height: var(--icon-sizing);
  width: var(--icon-sizing);
  color: var(--default-color);
  cursor: pointer;
  transition: color 0.3s;
  z-index: 10;
}

/* User Profile Modal */
#user-profile-image {
  outline: 2px solid #e2522b;
  border-radius: 50%;
}

.user-profile-modal {
  position: absolute;
}

.profile-username {
  color: #c4c4c4;
  font-size: calc((18.4 / 1440) * 100vw);
  margin-left: 1rem;
  text-transform: capitalize;
}

.profile-email {
  color: #c4c4c4;
  font-size: calc((14.4 / 1440) * 100vw);
  margin-left: 1rem;
  margin-top: -0.5rem;
}

.account-settings,
.sign-out {
  color: #c4c4c4;
  opacity: 0.9;
}

.account-settings:hover,
.sign-out:hover {
  opacity: 1;
  cursor: pointer;
}
