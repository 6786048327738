.mobileUnsupprted-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2.6354319180087846vw);
  -webkit-backdrop-filter: blur(2.6354319180087846vw);
  z-index: 999999;
}

@media screen and (width > 576px) {
  .mobileUnsupprted-wrapper {
    display: none;
  }
}

.mobileUnsupprted-wrapper .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.764705882352941vw;
}

.mobileUnsupprted-wrapper .koolio-logo-image {
  height: 8.791106471816285vw;
  width: 39.99874739039665vw;
}

.mobileUnsupprted-wrapper .unsupported-screen-text {
  font-size: 4vw;
  text-align: center;
  color: #c4c4c4;
}
