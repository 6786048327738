/* .half-modals-content {
  height: calc((211 / 1440) * 100vw) !important;
  width: calc((756 / 1440) * 100vw) !important;
} */

.half-modals-header {
  /* height: 25.7031px; */
  height: calc((26.7031 / 1440) * 100vw) !important;
  /* width: 658.234px; */
  width: calc((694.234 / 1440) * 100vw) !important;
  /* font-size: 17.075px; */
  font-size: calc((18.075 / 1440) * 100vw) !important;
  font-weight: 700;
}

@media screen and (width >= 1920px) {
  .half-modals-header {
    margin-left: 12px;
    margin-bottom: 12px;
  }
}

#closeWorkspaceModalIcon {
  height: 1.0416666666666667vw;
  width: 1.0416666666666667vw;
  cursor: pointer;
}

/* TODO: Make the alignment responsive */

#comment {
  /* height: 31.3594px; */
  height: calc((33.3594 / 1440) * 100vw) !important;
  /* width: 341.5px; */
  width: calc((359.8 / 1440) * 100vw) !important;
  /* font-size: 13px; */
  font-size: calc((14 / 1440) * 100vw) !important;
  position: relative;
  top: 0.3vh;
}

.half-modals-action {
  /* margin-top: 2.1vh; */
  margin-top: calc((2 / 1440) * 100vw) !important;
}

.half-modals-actions {
  width: fit-content;
  padding-right: 1.55vw;
}

.half-modals-actions button {
  /* height: 29.4844px; */
  height: calc((31 / 1440) * 100vw) !important;
  /* width: 95.6562px; */
  width: calc((100.6562 / 1440) * 100vw) !important;
  font-size: calc((14 / 1440) * 100vw) !important;
  text-wrap: nowrap;
}

#add-comment {
  /* width: 114.344px; */
  width: calc((121 / 1440) * 100vw) !important;
}

#delete {
  /* width: 129.578px; */
  width: calc((136.5 / 1440) * 100vw) !important;
}
