.transcriptionModal-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.3177159590043923vw);
  -webkit-backdrop-filter: blur(1.3177159590043923vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1015;
  overflow: hidden;
}

.transcriptionModal-wrapper #half-modals-content {
  height: 10.428477306002929vw;
  width: 52.49934114202049vw;
  display: flex;
  flex-direction: column;
}

.transcriptionModal-wrapper .half-modals-header {
  height: 1.8747730600292827vw;
  width: 48.18696925329429vw;
  font-size: 1.25vw !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: unset;
}

.transcriptionModal-wrapper .half-modals-header #closeWorkspaceModalIcon {
  height: inherit !important;
  width: 1.0409077598828695vw !important;
  cursor: pointer;
}

.transcriptionModal-wrapper .half-modals-action {
  height: 2.1584480234260615vw;
  width: 48.18696925329429vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: unset;
  margin-top: 2.049780380673499vw !important;
}

.transcriptionModal-wrapper .half-modals-action .half-modals-actions {
  height: auto;
  width: 6.985497803806734vw !important;
  display: flex !important;
  align-items: center !important;
  margin: unset;
}

.transcriptionModal-wrapper .half-modals-action .half-modals-action-button {
  height: 2.1584480234260615vw !important;
  width: 5.458455344070278vw !important;
  font-size: 0.972225475841874vw !important;
  text-align: center;
  margin: unset;
  padding: unset;
}
