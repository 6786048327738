.more-options-menu {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
}

.more-options {
  height: 70vh;
  width: 80%;
  margin-left: 10rem;
  margin-top: 5rem;
}

.options-body-delete {
  padding: 3rem;
}

.more-options-sidebar {
  background: rgb(18, 18, 18);
  background: linear-gradient(
    174deg,
    #181818 0%,
    rgba(24, 24, 24, 0.95) 35%,
    rgba(20, 20, 20, 0.8) 70%,
    rgba(18, 18, 18, 0.6) 100%
  );
  width: 12%;
}

.more-opt-logo {
  height: calc((31 / 1440) * 100vw);
  width: calc((60 / 1440) * 100vw);
  margin-top: 4vh;
}

.blur-background {
  backdrop-filter: blur(
    1.3177159590043923vw
  ); /* Adjust the blur strength as needed */
}

#icons {
  position: relative;
  top: 25px;
  width: 25.0104px;
  opacity: 0.9;
}

.options-header-cont {
  color: #c4c4c4;
  font-weight: 600;
  margin-left: 3%;
}

.secondhalf {
  padding: 1rem;
  position: fixed;
  top: 5%;
  width: 100vw;
  /* background-color: rgba(0, 0, 0, 0.5); */
  margin-left: 4%;
}

/* .more-options-menu-header {
    text-align: left;
    position: fixed;
    padding: 0px;
    left: 4%;
    left: 155px;
    top: 18px;
    font-size: 21.75px;
    line-height: 32.625px;
} */

[class*="more-options-menu-header"] {
  text-align: left;
  position: fixed;
  padding: 0px;
  /* left: 4%; */
  /* left: 155px; */
  left: 14%;
  /* top: 18px; */
  top: 2.79%;
  /* font-size: 21.75px; */
  font-size: calc((24 / 1440) * 100vw);
  /* line-height: 32.625px; */
  line-height: calc((35.5 / 1440) * 100vw);
  /* background-color: blue; */
}

[id*="close-more-options"] {
  position: fixed;
  padding: 0;
  margin: 0;
  /* top: 16px; */
  top: 3%;
  left: 97%;
  cursor: pointer;
  /* height: 19.625px; */
  height: calc((21.7 / 1440) * 100vw);
  /* width: 19.625px; */
  width: calc((21.7 / 1440) * 100vw);
}

[id*="close-gen-ai-options"] {
  position: fixed;
  padding: 0;
  margin: 0;
  /* top: 16px; */
  top: 3%;
  left: 97%;
  cursor: pointer;
  /* height: 19.625px; */
  height: calc((21.7 / 1440) * 100vw);
  /* width: 19.625px; */
  width: calc((21.7 / 1440) * 100vw);
}

.more-options-header-sfx {
  /* font-size: 18.694px; */
  font-size: calc((20 / 1440) * 100vw);
  margin-bottom: 0;
}

.project-filename {
  /* position: relative;
    padding: 0px;
    left: 10%;
    top: 60px;
    color: #c4c4c4; */
  color: #c4c4c4;
}

/* .format-opt-button {
  margin: 0 2%;
} */

.format-options span {
  font-size: calc((20.694 / 1440) * 100vw);
}

.export-formats {
  color: #c4c4c4;
  margin: 4.18% 0;
}

.submenu-opt-left {
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  left: 30%;
  display: flex;
  flex-direction: row;
}

.action-project-button {
  border: 1px solid white;
  /* border-radius: 11px; */
  border-radius: 4rem;
  color: #c4c4c4;
  background: transparent;
  padding: unset !important;
  text-wrap: nowrap;
}

.export-options {
  position: relative;
  bottom: 2.8vh;
  /* left: 2.5vw; */
  left: 2rem;
}

#btn-lq-download {
  /*    width: 10.3rem;*/
  /*    164px*/
  width: calc((182 / 1440) * 100vw);
  height: calc((39.3438 / 1440) * 100vw);
  /* margin-left: 0; */
}

#btn-hq-export {
  /*    width: 9.35rem;*/
  /*    149px*/
  height: calc((39.3438 / 1440) * 100vw);
  width: calc((165 / 1440) * 100vw);
  /* Redeclare the padding and text-wrap properpty to fix text alignment */
  padding: 0 calc((6 / 1440) * 100vw);
  text-wrap: nowrap;
}

#publish-button {
  height: calc((39.3438 / 1440) * 100vw);
  width: calc((165 / 1440) * 100vw);
}

#project-size {
  color: #c4c4c4;
  font-size: 0.78125vw; /* 12px */

  position: relative;
  bottom: 0.625vw; /* 0.6rem */

  margin: unset;
  padding-left: 0.5208333333333334vw; /* 0.5rem */
}

.dropdown {
  appearance: none;
  position: absolute;
  /* left: 10.5rem; */
  /* background: url(/static/img/arrow-down.png) no-repeat right rgb(12, 12, 12); */
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  /* width: 16rem; */
  /*    width: 208px;*/
  width: calc((230 / 1440) * 100vw);
  opacity: 0.9;
  /* margin-top: -0.2rem; */
  border-radius: 1vw;
  z-index: 0;
  /* padding: 0%; */
  background-color: transparent;
  margin-left: -9.67%;
  /*    height: 1.55rem;*/
  text-align: left;
  /* font-size: 16.3125px; */
  font-size: calc((18 / 1440) * 100vw);
}

.dropdown option {
  width: 164.354px;
  text-indent: 12.8625px;
  padding-top: 2.14375px;
  padding-bottom: 2.14375px;
  display: block;
}

#wav-bit-depth-parent,
#wav-sample-rate-parent,
#wav-channels-parent {
  padding: calc((3.8 / 1440) * 100vw) 0;
  text-indent: calc((18 / 1440) * 100vw);
  background-color: #0c0c0c;
}

.design {
  width: 1.2vw;
  height: 1.2vw;
  border: 2px solid #c4c4c4;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.format-opt {
  margin-left: -9.67%;
}

.format-opt label input {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.format-opt-button label {
  cursor: pointer;
}

label {
  color: #c4c4c4;
}

.format-label {
  left: 1%;
  position: relative;
  /* font-size: 16.3125px; */
  font-size: calc((18 / 1440) * 100vw);
}

.export-format-two {
  position: relative;
  top: 79%;
  color: #c4c4c4;
  /* margin-top: 5%; */
  margin: 4.18% 0;
}

.export-format-three {
  color: #c4c4c4;
  /* margin-top: 5%; */
  margin: 4.18% 0;
}

.export-format-one {
  color: #c4c4c4;
  /* margin-top: 4%; */
  margin: 4.18% 0;
}

.history-heading {
  color: #c4c4c4;
  /* font-size: 20px; */
  font-size: calc((20 / 1440) * 100vw);
  /* padding: 22px 0; */
  padding: calc((24 / 1440) * 100vw);
  /* gap: 1.2rem; */
  gap: calc((21.5 / 1440) * 100vw);
}

.history-heading div {
  width: calc((253.531 / 1440) * 100vw);
  /* background-color: red; */
}

/* .history-icon .menu-option-logo { */
/*    margin-top: 64px;*/
/*    95px*/
/* margin-top: calc((124 / 1440) * 100vw); */
/* margin-top: 17.9vh; */
/* } */

#sfx-gen-icon .menu-option-logo {
  /*    margin-top: 64px;*/
  /*    94px*/
  /*  margin-top: calc((119 / 1440) * 100vw);*/
  margin-top: 0;
}

.share-window {
  height: 3.6008418740849195vw !important;
  width: 80vw !important;
  /* margin-bottom: 28px; */
  margin-bottom: calc((30.5 / 1440) * 100vw);
}

.share-email-list-msg {
  /* width: 29rem; */
  /* width: 391.5px; */
  width: calc((393.5 / 1440) * 100vw);
  /* height: 31.9062px; */
  height: calc((35 / 1440) * 100vw);
  border: 1px solid white;
  border-radius: 4rem;
  font-size: calc((18 / 1440) * 100vw);
  text-indent: calc((18 / 1440) * 100vw);
  padding: 2px;
  background: black;
  color: #c4c4c4;
}

#share-modes {
  list-style: none;
  /* position: relative;
    margin-left: 2rem; */
  /* margin-top: 16px; */
  border: 1px solid white;
  padding-top: 2.25px;
  color: #c4c4c4;
  /* height: 29.9062px; */
  /* height: calc((32.9062 / 1440) * 100vw); */
  height: calc((35 / 1440) * 100vw);
  /* width: 4rem; */
  /* width: 126.875px; */
  width: 9.721595900439238vw !important;
  border-radius: 2rem;
  text-align: start;
  /* background-color: black; */
  text-wrap: nowrap;
  font-size: calc((18 / 1440) * 100vw);
  text-indent: calc((18 / 1440) * 100vw);
  background: rgb(12, 12, 12);
}

#share-modes i {
  margin-top: -0.2vw;
  margin-right: -24px;
}

#share-modes-grandparent {
  width: 9.721595900439238vw !important;
  margin-left: calc((7 / 1440) * 100vw);
  margin-right: 7.5vw;
}

.dd-items,
.dd-items-alt {
  /* margin-left: calc((7 / 1440) * 100vw); */
  /* margin-right: calc((26 / 1440) * 100vw); */
  width: 9.721595900439238vw !important;
  margin: 0 !important;
  margin-left: 2.9vw !important;
  margin-top: -2vw !important;
  margin-bottom: -2vw !important;
}

.dd-items:has(#wav_31) {
  margin-top: -0.755vw !important;
  margin-bottom: -2vw !important;
}

.dd-item,
.dd-item-alt {
  cursor: pointer;
  text-indent: 1.25vw !important;
  transition: color 0.3s;
}

.dd-item:hover,
.dd-item-alt:hover {
  color: blueviolet;
}

#share-project {
  position: relative;
  top: -9px;
  /* margin-left: 5.15rem; */
  /* height: 31.9062px; */
  height: calc((35 / 1440) * 100vw);
  /* width: 145px; */
  width: calc((160 / 1440) * 100vw);
  border: 1px solid white;
  background: #0c0c0c !important;
  color: #c4c4c4;
  border-radius: 3rem;
  /* padding: 4px; */
  font-size: calc((18 / 1440) * 100vw);
  /* padding: 5px; */
  text-wrap: nowrap;
  /* width: 6rem; */
}

#share-project:hover {
  background-color: #e2522b !important;
}

@media screen and (width <=750px) {
  #share-modes-grandparent {
    margin-right: 12.5vw;
  }
}
@media screen and (width >=750px) and (width <=950px) {
  #share-modes-grandparent {
    margin-right: 9.5vw;
  }
}
@media screen and (width >=1500px) {
  #share-modes-grandparent {
    margin-right: 5.5vw;
  }
}

/* .more-options-header-genAi {
    position: relative;
    padding: 4rem;
    color: aliceblue;
} */

.gen-sfx-spinner {
  display: none;
  position: absolute;
  top: 40%;
  left: 44%;
}
#genSFXSearchBar,
#genSFXSearchBar input,
#genMusicSearchBar,
#genMusicSearchBar input,
#genImageSearchBar,
#genImageSearchBar input {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4rem;
  color: #c4c4c4;
  text-indent: 0px;
  /* margin-left: 0px;
  margin-top: 4px;
  margin-bottom: 0; */
  /* width: 80%; */
  /* height: 26.5469px; */
  height: calc((28.4 / 1440) * 100vw);
  /* width: 471.391px; */
  /* width: calc((500 / 1440) * 100vw); */
  width: 35.02240117130307vw !important;
  padding: 0% 2%;
  text-align: center;
  font-size: calc((18 / 1440) * 100vw);
  font-weight: 900;
  position: absolute;
  top: 22px;
  left: 7px;
  margin: 0;
  anchor-name: --anchor-gen-search;
}

.gen-info-icon > i {
  font-size: 1.25vw;
  position: absolute;
  position-anchor: --anchor-gen-search;
  top: anchor(20%) !important;
  right: anchor(99%);
  margin: 0 !important;
}

@-moz-document url-prefix() {
  #genSFXInputContainer,
  #genMusicInputContainer,
  #genImageInputContainer {
    height: max-content;
    width: max-content;

    position: relative;
    top: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  #genSFXSearchBar,
  #genMusicSearchBar,
  #genImageSearchBar {
    position: static;
  }

  #create-gen-sfx-button,
  #create-gen-music-button,
  #create-gen-image-button {
    margin-top: 0.5rem !important;
  }

  .gen-info-icon {
    height: max-content;
    width: max-content;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 1%;
  }

  .gen-info-icon > i {
    position: static;
  }
}

.genAiContent {
  height: 65vh !important;
  display: flex;
  justify-content: center;
  /* margin-left: 4rem; */
}

.create-music-button {
  height: calc((28.4 / 1440) * 100vw);
  width: calc((82 / 1440) * 100vw);
  font-size: 1.25vw !important;
  padding: 0 !important;
  border: 1px solid white;
  background: transparent;
  border-radius: 4rem;
  color: #c4c4c4;
  position: fixed;
  position-anchor: --anchor-gen-search;
  top: anchor(top) !important;
  right: anchor(121.25%) !important;
  margin: 0 !important;
  /* margin-top: 2vh !important; */
}

#create-gen-sfx-button,
#create-gen-music-button,
#create-gen-image-button {
  text-wrap: nowrap;
  padding: 0 12px;
}

.action-project {
  /* background-color: red; */
  position: relative;
  top: 11px;
  left: -41px;
}

#generateButton {
  position: relative;
  left: -2%;
}

#show-notes-project-button {
  /* height: 36.5781px; */
  height: calc((39 / 1440) * 100vw);
  /* width: 149.547px; */
  width: calc((160 / 1440) * 100vw);
  font-size: 1.25vw;
  margin-top: 4.1vh;
  left: 0.4vw !important;
}

#generateButton button {
  position: absolute;
  left: 0;
  z-index: 999;
}

.gen-sfx-arrows,
.gen-music-arrows,
.gen-image-arrows {
  height: 2.342606149341142vw;
  width: 2.342606149341142vw;
  opacity: 0.2;
  position: relative;
  /* top: 12%; */
  left: 45.2%;
}

#prevGenSFXContainer,
#prevGenMusicContainer,
#prevGenImageContainer {
  /* background-color: red; */
  position: relative;
  left: -4.2%;
}

#prevGenSFXContainer p,
#prevGenMusicContainer p,
#prevGenImageContainer p {
  font-size: calc((18 / 1440) * 100vw);
  text-align: center;
  margin-top: 22px;
}

.prev_gen_sfx,
#prev_gen_musics,
#prev_gen_image {
  margin-top: 17%;
  height: 36.821917808219176vh !important;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.prev_gen_sfx a,
#prev_gen_musics a,
#modal_gen_music a,
#prev_gen_image a {
  display: block;
  /* margin-bottom: 1.720351390922401vw; */
  /* font-size: 18px; */
  font-size: 1.25vw;
  font-weight: 900;
  /* height: 3.440702781844802vw; */
  height: max-content;
  padding: 0.8vw 0;
  width: 100%;
  color: #fff !important;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}

.prev_gen_sfx a:hover,
#prev_gen_musics a:hover,
#modal_gen_music a:hover,
#prev_gen_image a:hover {
  color: blueviolet !important;
}

#modal_gen_sfx {
  position: relative;
  top: 4vh;
  left: -3.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: calc((18 / 1440) * 100vw);
}

#modal_gen_image {
  position: relative;
  top: 12.9vh;
  left: -3.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: calc((18 / 1440) * 100vw);
}

#modal_gen_music {
  position: relative;
  top: 4.3vh;
  left: -3.9vw;
}

#createSFXLoader {
  /* 40 from top */
  /* 229 from right */
  position: relative;
  left: -3.9vw;
  margin-bottom: 0;
}

#createMusicLoader {
  /* 29 from top */
  /* 204 from right */
  position: relative;
  top: 3.8vh;
  left: -3.9vw;
}

#createImageLoader {
  position: relative;
  top: 8.9vh;
  left: -3.9vw;
}

::-webkit-scrollbar {
  display: none;
}

#prev_gen_musics,
#prev_gen_sfx,
#prev_gen_image {
  height: 15vw;
  margin: 2vh 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  box-shadow:
    0px -17px 20px -20px rgba(54, 53, 53, 0.45),
    0px 17px 20px -20px rgba(54, 53, 53, 0.45);
}

#modal_gen_music a {
  display: block;
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
  width: auto; /* Set your desired width */
  height: auto; /* Set your desired height */
  object-fit: cover;
}

/* .hoverable-image {
    width: auto;
    height: auto;
    margin-top: 4%;
    margin-bottom: 0%;
    max-width: 70%;
    max-height: 75%;
    object-fit: cover;
    object-position: center;
    transition: filter 0.3s ease;
} */

.hovered {
  filter: blur(3px);
}

.image-container:hover {
  opacity: 1;
}

/* Style for the image thumbnail */
.thumbnail {
  width: 7vw; /* Adjust the width of the thumbnail as needed */
  height: 10vh; /* Adjust the height of the thumbnail as needed */
  object-fit: cover;
  margin-top: 3vh;
  margin-bottom: 4vh;
  margin-left: 4vh;
  margin-right: 4vw; /* Adjust the margin between image and title as needed */
  cursor: pointer;
}

.btn-active {
  background: #e2522b !important;
  opacity: 1 !important;
  color: #ffffff !important;
}

#aiTitles,
#aiSocials,
#aiKeywords,
#aiHighlights {
  color: #c4c4c4;
}

.more-options-header {
  justify-content: space-between;
  color: #c4c4c4;
  opacity: 0.9;
  margin-top: 5% 0 0 0;
}
.row {
  align-items: center;
}

#history-icon {
  anchor-name: --history-icon;
}

.options-body {
  height: max-content;
  width: 80%;
  position: fixed;
  position-anchor: --history-icon;
  /* left: 10%; */
  margin-left: calc((204.5 / 1440) * 100vw);
  top: calc(anchor(top) - 0.5vw);
  /* margin-top: 19vh; */
  color: #c4c4c4;
  opacity: 0.9;
  /* font-size: 18.694px; */
  font-size: calc((20 / 1440) * 100vw);
}

.options-genai-body {
  /* height: 362.734px; */
  font-size: calc((20 / 1440) * 100vw);
  height: calc((387.734 / 1440) * 100vw);
  /* width: 1010.16px; */
  width: calc((1081.16 / 1440) * 100vw);
  position: relative;
  margin-top: 6%;
  margin-left: calc((140 / 1440) * 100vw);
  color: #c4c4c4;
  opacity: 0.9;
  /* background-color: red; */
}

select {
  width: 15vw;
  text-align: center;
}

option {
  background-color: black;
  color: #c4c4c4;
}

.project-output-filename {
  cursor: text;
  color: #e2522b !important;
  font-weight: 600;
  left: 20.4%;
}

.project-output-filename:focus {
  outline: none;
}

#normalize-checkbox-uo {
  position: absolute;
  top: 20%;
  left: 20.9%;
  height: 52%;
}

.action-project {
  margin: -1% 0;
  /*    font-size: 16.3125px;*/
  font-size: calc((18 / 1440) * 100vw);
  margin-left: 0.8rem;
}

.normal-export {
  margin-left: 0;
}

#delete-body {
  /* margin-left: 194px; */
  margin-left: calc((214 / 1440) * 100vw);
}

#delete-header {
  /* font-size: 18.75px; */
  font-size: calc((20 / 1440) * 100vw);
}

#delete-project-button {
  /* margin-top: 46px; */
  margin-top: calc((51 / 1440) * 100vw);
  padding: calc((6 / 1440) * 100vw);
  text-wrap: nowrap;
  /* height: 35.9062px; */
  height: calc((39 / 1440) * 100vw);
  /* width: 145px; */
  width: calc((160 / 1440) * 100vw);
  /* font-size: 16.3125px; */
  font-size: calc((18 / 1440) * 100vw);
  background-color: #0c0c0c !important;
}

#delete-project-button:hover {
  background-color: #e2522b !important;
}

.gen-ai-text-slide {
  width: 99.5vw;
  font-size: calc((12 / 1440) * 100vw);
}

[contenteditable="true"] {
  user-select: none;
  outline: none !important;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7.8vh;
  margin-top: 11.4vh;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

#share-icon,
#delete-icon,
#history-icon,
#export-icon,
#genAi-icon,
#sfx-gen-icon,
#music-gen-icon,
#gen-img-icon,
#show-notes-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;

  .menu-option-logo {
    height: 1.5625vw;
    width: 1.5625vw;
    color: #c4c4c4;
    transition: color 0.3s;
  }

  .menu-option-logo:focus {
    outline: none;
  }

  .icon-label {
    transition: color 0.3s;
    font-size: 1vw;
    margin-bottom: 0;
  }
}

#export-icon {
  .menu-option-logo {
    color: #e2522b;
  }
  .icon-label {
    color: #e2522b;
  }
}

#share-icon:hover,
#delete-icon:hover,
#history-icon:hover,
#export-icon:hover,
#genAi-icon:hover,
#sfx-gen-icon:hover,
#music-gen-icon:hover,
#gen-img-icon:hover,
#show-notes-icon:hover {
  .menu-option-logo {
    color: #e2522b !important;
  }

  .icon-label {
    color: #e2522b !important;
  }
}
