.controls,
.playlist-top-bar {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.col-8-mod {
  width: 58% !important;
}

.col-2-mod {
  width: 21% !important;
}

.col-1-mod {
  width: 10.5% !important;
}

.col-11-mod {
  width: 89.5% !important;
}

.spk-track-controls.col-1-mod {
  width: 16% !important;
}

.add-track-controls.col-1-mod {
  background-color: #181818;
  width: 5% !important;
}

.control-opt-preview {
  font-weight: 700;
  letter-spacing: 0.02rem !important;
  /* font-size: 19px; */
  cursor: pointer;
}

.control-opt-edit {
  color: #494949;
  font-weight: 700;
  letter-spacing: 0.02rem !important;
  cursor: pointer;
  /* font-size: 18px; */
}
.control-undo,
.control-redo {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.controls,
.playlist-top-bar {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.disabled-ctrl {
  display: none !important;
}
.icon-spacing {
  margin-left: calc((((25 / 1920) * 100) * 100vw) / 100);
  margin-right: calc((((25 / 1920) * 100) * 100vw) / 100);
  cursor: pointer;
}
.button {
  cursor: pointer;
  margin-left: 1.3rem;
  margin-right: 1.3rem;
}

.nested-recording-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: transparent;
  backdrop-filter: blur(1.3177159590043923vw);
}
.disable-operation-btns {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
