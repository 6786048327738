.uploadProject-active {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 26vw;
  /* margin-top: 2%; */
}

.uploadProject {
  display: none;
}

.addBtn-active {
  transform: rotate(45deg);
}

/* .uploadedFiles {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: clamp(18px, 1.5vw, 34px);
  font-size: calc(min(2.25vw, max(18px, 1.5vw)));
  margin-top: 1rem;
} */

.uploadFileContainer {
  height: auto;
  width: fit-content;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 1vw;
}

.uploadedFiles {
  height: auto;
  width: fit-content;
  color: white;
  font-size: 1.333rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  margin-top: 1.152rem;
  scroll-snap-align: start;
}

.hideProjectList {
  display: none;
}

.viewBtn {
  padding-right: 1.5rem;
  display: inline;
  color: #8a2be2 !important;
  outline-style: none !important;
  border: none !important;
  background: transparent !important;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2rem;
  transition: 0.3s;
}

.viewBtn:hover {
  transform: scale(1.2);
}

.deleteBtn {
  padding-left: 1.5rem;
  display: inline;
  color: #e2522b;
  outline-style: none !important;
  border: none !important;
  background: transparent !important;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2rem;
  transition: 0.3s;
}

.deleteBtn:hover {
  transform: scale(1.2);
}

@media screen and (width <= 768px) {
  .viewBtn {
    padding-right: 0.8rem;
  }
  .deleteBtn {
    padding-left: 0.8rem;
  }
}

#waveform_time {
  display: none;
}

.footer {
  position: fixed;
  /* left: 0; */
  /* float: right; */
  bottom: 0;
  width: 100%;
  /* background-color: red; */
  color: white;
  text-align: right;
  padding-right: 2%;
  font-style: italic;
  font-size: 0.7em;
  z-index: 98;
}

/* Chevron Icons */
.chevron-up,
.chevron-down {
  height: 3vw;
  width: 3vw;
  color: #c4c4c4;
  position: fixed;
  position-anchor: --project-titles;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  opacity: 0.2;
  transition:
    opacity 0.3s,
    color 0.3s;
}
.chevron-up:hover,
.chevron-down:hover {
  color: #e2522b;
  opacity: 1;
}
.chevron-up {
  /* top: calc(anchor(top) - 3.4rem); */
  top: calc(anchor(top) - 2.8333333333333335vw);
}
.chevron-down {
  /* bottom: calc(anchor(bottom) - 3.4rem); */
  bottom: calc(anchor(bottom) - 2.8333333333333335vw);
}

@-moz-document url-prefix() {
  .chevron-up,
  .chevron-down {
    position: static;
    transform: none;
  }
  .chevron-icons-container {
    height: max-content;
    width: max-content;

    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    gap: 22rem;
  }
}

/* Upload Options */
.kooliospace-footer {
  height: max-content;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding-top: 3vh;
  padding-bottom: 3vh;
  background-color: #181818;
}

.kooliospace-footer .kooliospace-footer-upload-options {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  margin-left: auto;
  margin-right: auto;
}

.kooliospace-footer .kooliospace-footer-upload-options .upload-option {
  height: max-content;
  width: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .upload-option-icon {
    height: 1.5vw;
    width: 1.5vw;
    color: white;
    cursor: pointer;
    margin-bottom: 0.5vw;
    transition: color 0.3s;
  }

  .upload-option-icon:hover {
    color: #e2522b;
  }

  .upload-option-text {
    font-size: 1vw;
    color: #c4c4c4;
    opacity: 0.4;
    margin-bottom: 0;
  }
}

/* Upload Options Expanded */
.kooliospace-center {
  height: 85vh;
  width: 100vw;
  background-color: #181818;
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.kooliospace-center .koolio-center-footer-text {
  font-size: 1vw;
  color: #c4c4c4;
  opacity: 0.4;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2vh;
}

.kooliospace-center .kooliospace-center-upload-options {
  height: max-content;
  width: max-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8vw;
}

.kooliospace-center .kooliospace-center-upload-options .upload-option {
  height: max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .upload-option-icon {
    height: 2.25vw;
    width: 2.25vw;
    color: white;
    cursor: pointer;
    margin-bottom: 0.5vw;
    transition: color 0.3s;
  }

  .upload-option-icon:hover {
    color: #e2522b;
  }

  .upload-option-text {
    font-size: 1.5vw;
    color: #c4c4c4;
    opacity: 0.4;
    margin-bottom: 0;
  }
}
