#modalWrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.9375vw);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

#modalWrapper .half-modals-action {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 3.4895833333333335vw !important;
}

#modalWrapper .half-modals-actions {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6666666666666667vw;
  margin-top: 0.8333333333333334vw;
}

#modalWrapper .half-modals-actions > div {
  height: max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  gap: 1.5270833333333333vw;
}

#modalWrapper .half-modals-action-button {
  height: max-content !important;
  width: max-content !important;
  font-size: 0.9722395833333335vw !important;
  padding: 0.325vw 1.8vw;
  border-radius: 2vw !important;
  position: static !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

#modalWrapper .half-modals-action-button img {
  height: 1.40625vw;
  width: 1.1979166666666667vw;
  object-fit: contain;
  margin-right: 0.5vw;
}
