.recordingName-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  backdrop-filter: blur(1.3177159590043923vw);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.recordingName-modal {
  position: fixed;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 41.35vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  z-index: 1001;
}

.recordingName-modal div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  height: fit-content;
  width: 75%;
}

.record-btn-options {
  background: #0c0c0c;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  width: 11vw;
  height: 4.75vh;
  /* font-size: 12.62px; */
  font-size: calc((12.62 / 1440) * 100vw);
  margin: 0;
  margin-left: calc((24 / 1440) * 100vw);
  margin-right: calc((24 / 1440) * 100vw);
  margin-top: -1.7%;
  text-align: center;
}

@-moz-document url-prefix() {
  .record-btn-options {
    margin-top: 4.25%; /* For Mozilla Firefox */
  }
}

.record-btn-options:hover {
  background-color: #e2522b !important;
}

.recordModalInput {
  width: 100%;
  margin: 5% 0;
  /* height: 41.2px; */
  height: calc((41.2 / 1440) * 100vw);
  /* font-size: 16.825px; */
  font-size: calc((16.825 / 1440) * 100vw);
  background-color: #181818 !important;
  border: none;
  outline: none;
  text-decoration: none;
  color: #c4c4c4;
  border-bottom: 1px solid #e2522b;
  padding-left: 10px;
}

/* @media screen and (max-width: 550px) {
    .recordingName-modal {
        width: 60vw !important;
    }
    .record-btn-options{
        width: 20vw !important;
    }
    } */
