.right-aligned-icons .nav-link {
  padding: 0 0.5rem;
}

.kooliospaceNavbar {
  display: flex;
  justify-content: flex-end;
}

.navbar {
  background-color: black;
}

/* .notification_icon {
  max-height: 20px;
} */

:root,
::after,
::before {
  --var-color-primary: #9c26f6;
  --var-color-secondary: #e2522b;
  --var-color-text: #c4c4c4;
  --var-color-background: #181818;
  --var-color-background-dark: #0c0c0c;
}

* {
  margin: 0;
  padding: 0;
}

.col-8-mod {
  width: 58% !important;
}

.col-2-mod {
  width: 21% !important;
}

.col-1-mod {
  width: 10.5% !important;
}

.col-11-mod {
  width: 89.5% !important;
}

.spk-track-controls.col-1-mod {
  width: 16% !important;
}

.add-track-controls.col-1-mod {
  background-color: #181818;
  width: 5% !important;
}

/* .site-header {
    position: absolute;
    top: 0%;
    z-index: 99 !important;
    position: fixed;
    background-color: #181818 !important;
} */

.site-header-home {
  position: absolute;
  top: 0%;
  /* z-index: 99 !important; */
  position: fixed;
  background-color: transparent !important;
}

.dynamic {
  font-weight: 900;
}

.project-name {
  /* font-size: 24px; */
  color: #fff;
  font-weight: 900;
}

/* Transcript */

.transcriptSearch-icon {
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
  color: #fff;
}

.transcriptSearch-img img {
  /* width: 0.8rem;
    height: 0.8rem; */
  cursor: pointer;
}

.transcriptSearch-icon:hover {
  color: blueviolet;
}

/* .transcript-header {
    margin-top: 1.6rem !important;
    margin-bottom: 2rem !important;
} */

.transcriptSearch {
  opacity: 0.5;
  border: 1px solid white !important;
  border-radius: 2vw;
  outline: none;
  transition: border-color 0.01s linear;
  text-indent: 0.3vw !important;
  padding-left: 0.3vw !important;
}

.transcriptSearch:hover,
.transcriptSearch:focus,
.transcriptSearch.active {
  opacity: 1;
  border: 1px solid #a5a5a5 !important;
  outline: none;
  border-radius: 2vw;
}

.transcriptSearch.sfx {
  width: 38%;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .transcript-loader,
  .vol-ctrl-menu {
    -webkit-backdrop-filter: blur(1.3177159590043923vw);
    backdrop-filter: blur(1.3177159590043923vw);
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .transcript-loader,
  .vol-ctrl-menu {
    background-color: rgb(24, 24, 24, 0.98);
  }
}

/* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .more-options-menu {
    background: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(1.3177159590043923vw);
    backdrop-filter: blur(1.3177159590043923vw);
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .more-options-menu {
    background-color: rgb(24, 24, 24, 0.98);
  }
} */

/* workspace playlist controls */

.control-opt-preview {
  font-weight: 700;
  letter-spacing: 0.02rem !important;
  /* font-size: 19px; */
  font-size: calc((18 / 1440) * 100vw);
  cursor: pointer;
}

.control-opt-edit {
  color: #494949;
  font-weight: 700;
  letter-spacing: 0.02rem !important;
  cursor: pointer;
  font-size: calc((18 / 1440) * 100vw);
  /* font-size: 18px; */
}

.control-separator {
  opacity: 0.6;
}

.addon-separator {
  opacity: 0.6;
}

.control-undo,
.control-redo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.disabled-ctrl {
  display: none !important;
}

sup {
  color: #e2522b;
}

.sfx-text {
  font-weight: 900;
  font-weight: bold;
}

/* Playlist Area */

/* .speakers-sliders,
.sfx-sliders {
  top: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline-style: none;
}

.slider-dp {
  opacity: 0.1;
  transition: 0.2s ease-out;
  text-align: center;
  outline-style: none;
  transform: scale(0.8);
}

.slick-current {
  opacity: 1;
  transition: 0.2s ease-out;
  transform: scale(1);
  outline-style: none;

}
=======
} */

/* .slick-current > a > img {
    border: 3px solid blueviolet;
} */

.spk-slid {
  margin: auto;
  outline: none;
}

.sliders-track {
  background-color: transparent;
  position: relative;
  color: #fff;
  /* margin-bottom: 20px; */
  padding-left: 0.6rem;
  display: flex;
  align-items: center;
}

a {
  outline-style: none;
}

.ctrl-vol {
  display: flex;
}

.speaker-volume {
  position: relative;
  /* margin-top: 24%; */
}

.sfx-volume {
  position: relative;
  /* margin-top: 21%; */
}

/* sfx slider */

.slick-track {
  text-align: center;
}

.slick-slide.selected a span.text,
.slider-sfx,
.slick-current {
  /* border: none; */
  transform: none !important;
  outline: none;
}

.slick-next {
  left: 70% !important;
  top: 45%;
}

.slick-prev {
  left: 20% !important;
  top: 45%;
  z-index: 99;
}

/* volume controls */

/* .vol-ctrl-menu {
    height: 101%;
    width: 9vw;
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    background-position: 100%;
    background-size: 125%;
    background-image: url(/image/workspace/controls/bgTrackSlider.png);
    /* background: rgba(48, 48, 48, 0.2); */

.amplitude-container {
  position: absolute;
  background: #2f2f2f;
  height: 50%;
  width: 30%;
  bottom: 18%;
  left: 20%;
}

.amplitude-way-one {
  width: 30%;
  height: 60%;
  left: 18%;
  position: absolute;
  bottom: 0%;
  background: linear-gradient(180deg, #e2522b 0%, rgba(196, 196, 196, 0) 100%);
}

.amplitude-way-two {
  width: 30%;
  height: 80%;
  position: absolute;
  bottom: 0%;
  right: 18%;
  background: linear-gradient(180deg, #e2522b 0%, rgba(196, 196, 196, 0) 100%);
}

.volume-container {
  position: absolute;
  background: #2f2f2f;
  height: 50%;
  width: 10%;
  bottom: 18%;
}

.mute-solo-opt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mute {
  position: absolute;
  bottom: 0%;
  left: 10%;
  font-size: 1.2rem;
  font-weight: 900;
  color: #c4c4c4;
}

.solo {
  left: 48%;
  position: absolute;
  bottom: 0%;
  font-size: 1.2rem;
  font-weight: 900;
  color: #c4c4c4;
}

.ui-slider-range-min {
  position: absolute;
  width: 100%;
  background: #9c26f6;
  border: none;
  outline: none;
  bottom: 0%;
}

.knob,
.knob-clean {
  position: absolute;
  top: 7.5%;
  margin: auto;
  width: 3.2rem;
  height: 3.2rem;
}

.knob .knob-knob {
  transition:
    width 0.2s,
    height 0.2s;
  background-color: #111 !important;
  border: 2px solid #222;
  box-shadow:
    0px 0px 20px rgba(0, 0, 0, 1),
    0px 10px 5px rgba(0, 0, 0, 0.7),
    inset 0px 10px 10px rgba(255, 255, 255, 0.05);
}

.knob .knob-stroke {
  transition: stroke-width 0.2s;
}

.knob-stroke {
  fill: transparent !important;
  stroke: #e2522b !important;
  stroke-width: 11% !important;
}

.knob.grab .knob-stroke {
  stroke-width: 11% !important;
}

.knob .knob-pointer {
  background: #3ff !important;
}

.knob .knob-knob::before {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 70%;
  height: 70%;
  content: " ";
  background: linear-gradient(
    -25deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.07)
  );
  z-index: 3;
  filter: blur(1px);
  border-radius: 100%;
}

.knob.grab .knob-knob {
  width: 80% !important;
  height: 80% !important;
}

.knob .knob-pointer {
  box-shadow:
    inset 0px 0px 5px rgba(0, 0, 0, 1),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

.knob-knob {
  /* height: 100% !important; */
  width: 100% !important;
  background: transparent !important;
}

.knob-pointer {
  top: 0% !important;
  height: 45% !important;
  width: 4% !important;
  background-color: #9c26f6 !important;
}

.knob-outline {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -9999;
  top: 7.1%;
  z-index: 50 !important;
}

.knob-svg {
  position: absolute;
  z-index: 60 !important;
}

/* more-options-menu */

.options-header-cont {
  color: #c4c4c4;
  font-weight: 600;
}

.a img {
  width: 20px;
}

.menu-option {
  color: #c4c4c4;
  cursor: pointer;
}

/* More-options-contents */

.more-options-alert,
.gen-options-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: right;
  padding-right: 2%;
  font-style: italic;
  font-size: 0.7em;
  z-index: 100;
}

.more-options-header {
  color: #c4c4c4;
  opacity: 0.9;
}

.share-email-list input {
  width: 30vw;
  background: rgb(12, 12, 12);
  border: 1px solid #c4c4c4;
  border-radius: 2vw;
  /* height: 45px; */
  color: #c4c4c4;
  /* text-indent: 0.5rem; */
}

.share-email-list .suggestion {
  visibility: hidden;
  position: absolute;
  border: 1px solid #c4c4c4;
  width: 34vw;
  border-radius: 1vw;
  max-height: 9.5vw;
  background-color: #181818;
  opacity: 8;
  z-index: 10;
  overflow: hidden;
}

.half-modals-input-field .suggestion {
  /* visibility: hidden; */
  display: none;
  /* position: absolute; */
  border: 1px solid rgba(196, 196, 196, 0.7);
  width: 25vw;
  border-radius: 1vw;
  max-height: 9.5vw;
  background-color: #181818;
  opacity: 1;
  z-index: 10;
  overflow: hidden;
}

.suggestion {
  color: #c4c4c4;
}

.suggestion .searchList {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
}

#showList {
  height: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#showList::-webkit-scrollbar {
  display: none;
}

#showTitleList li {
  opacity: 0.7;
}

/* #podcast-id {
    background: url(/image/arrow-down.png) no-repeat right;
    cursor: pointer;
} */

.searchList li {
  cursor: pointer;
  padding-left: 1vw;
}

.searchList li:hover {
  border-radius: 1vw;
  background-color: #9c26f6;
  width: 100%;
}

/* ::-webkit-scrollbar-thumb {
    border-radius: 1vw;
} */

/* ::-webkit-scrollbar-track {
    border-radius: 1vw;
} */

.action-project button {
  background: #0c0c0c;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  /* opacity: 0.8; */
}

.action-project button:hover {
  background: #e2522b;
  opacity: 1;
  color: #ffffff;
}

.half-modals-actions button,
.half-modals-actions-inputs button {
  background: #0c0c0c;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  opacity: 0.8;
}

.half-modals-actions-inputs button {
  border-radius: 0.7vw;
}

.half-modals-actions button:hover,
.half-modals-action-inputs button:hover {
  background: #e2522b;
  opacity: 1;
  color: #ffffff;
}

.modal-input,
.modal-select {
  background-color: transparent;
  position: relative;
  margin-left: 2vw;
  color: var(--var-color-text);
  /*font-weight: 600;
    text-indent: 0.5vw;*/
  width: auto;
  border-radius: 2vw;
  border: solid 1px var(--var-color-text);
}

.modal-input {
  text-indent: 0.5vw;
}

.modal-select {
  background-color: var(--var-color-background);
  padding-left: 0.3vw;
}

.api-input {
  background-color: transparent !important;
  color: #fff;
  font-weight: 900;
  text-indent: 0.5vw;
  width: 25vw;
  border-radius: 2vw;
  border: solid 1px rgba(196, 196, 196, 0.4);
}

.speaker-input {
  background-color: transparent !important;
  position: relative;
  right: 420px;
  color: #e2522b;
  font-weight: 900;
  text-indent: 0.5vw;
  width: auto;
  border-radius: 1vw;
  border: solid 1px rgba(0, 0, 0, 1);
}

.btn-active {
  background: #e2522b !important;
  opacity: 1 !important;
  color: #ffffff !important;
}

/*
#btn-magic img {
    width: 1.458vw;
}
*/

.project-output-filename {
  cursor: text;
  color: #e2522b !important;
  font-weight: 600;
}

.export-format {
  color: #c4c4c4;
  opacity: 0.9;
  /* margin-top: 3rem; */
}

.format-opt {
  /* margin-top: 3rem; */
  display: flex;
}

.format-opt-button label {
  cursor: pointer;
}

.format-label {
  color: #c4c4c4;
  margin-left: 0.6vw;
}

.bit-depth-option {
  position: absolute;
  color: #c4c4c4;
}

/* .format-options {
    margin-top: 2rem;
} */
/*
.export-format-one, .export-format-two, .export-format-three {
    padding: 1.5rem 0rem;
} */

.format-settings-title {
  color: #c4c4c4;
  font-size: 1.2rem;
  opacity: 0.9;
}

.format-opt label input {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.design {
  width: 1.2vw;
  height: 1.2vw;
  border: 2px solid #c4c4c4;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.design::before,
.design::after {
  content: "";
  display: block;
  width: 1vw;
  height: 1vw;
  border-radius: inherit;
  position: absolute;
  transform: scale(0);
  transform-origin: center center;
}

.design:before {
  background: #9c26f6;
  opacity: 0;
  transition: 0.1s;
}

.design::after {
  background: #9c26f6;
  opacity: 0.4;
  transition: 0.1s;
}

/* checked state */

.format-opt label input:checked + .design::before {
  opacity: 1;
  transform: scale(0.7);
}

/* Dropdown */

.format-opt-sub-menu select {
  background: rgb(12, 12, 12);
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  border-radius: 3px;
  width: 35vh;
  margin-left: -10vh;
  height: 2.5rem;
  margin-top: -1rem;
  opacity: 0.8;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.3rem;
  /* background: url(/image/arrow-down.png) no-repeat right rgb(12, 12, 12); */
}

#normalize-checkbox-uo,
#ai-enhancement-checkbox-uo {
  cursor: pointer;
  position: absolute;
}

/* .normalize-label input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.normalize-label label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #c4c4c4;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0.47584187408491946vw;
    position: absolute;
    cursor: pointer;
    left: 16.398243045387993vw;
    margin-top: 0.5755395683453237vh;
}

.normalize-label input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0.4316546762589928vh;
    left: 16.764275256222547vw;
    width: 0.43923865300146414vw;
    height: 1.870503597122302vh;
    border: solid 0.14641288433382138vw blueviolet;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } */

/* history */

.options-body > hr {
  margin: 0 !important;
}

.history-main {
  padding: 2vh 0;
  height: 45vh;
  width: 75vw;
  overflow-y: scroll;
  color: #c4c4c4;
}

.genAiContent {
  padding: 2vh 0;
  height: 50vh;
  overflow-y: scroll;
  color: #c4c4c4;
}

/* .gen-info-icon {
  position: relative;
  right: 5%;
  display: inline;
} */

.history-heading {
  padding: 3.5vh 0;
}

.history-version {
  padding: 3vh 0;
  gap: calc((21.5 / 1440) * 100vw);
}

.history-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((253.531 / 1440) * 100vw);
  font-size: calc((18 / 1440) * 100vw);
  color: #c4c4c4;
  cursor: default;
}

/* .history-data:hover {
  color: #9c26f6 !important;
  cursor: pointer;
} */

.history-data > .restore-disabled {
  opacity: 0.5;
  cursor: pointer;
}

.history-data > .restore-enabled {
  cursor: pointer;
}

.history-main::-webkit-scrollbar {
  display: none;
}

.history-main {
  scrollbar-width: none;
}

.genAiContent::-webkit-scrollbar {
  display: none;
}

.history-main {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.genAiContent {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.page-link {
  color: #c4c4c4;
  background-color: transparent;
  cursor: pointer;
  font-size: smaller;
}

.page-link:hover {
  color: #c4c4c4;
  background-color: #e2522b;
}

#historyPageContainer {
  flex-wrap: wrap;
}

#historyPageControl {
  width: 75vw;
  margin-top: 1vw;
}

#history-page-arrow-left {
  position: absolute;
  left: -5%;
  top: 45%;
  cursor: pointer;
}

#history-page-arrow-right {
  position: absolute;
  right: 3%;
  top: 45%;
  cursor: pointer;
}

#history-loading {
  color: #8a2be2 !important;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-45%, -50%);
}

#share-history-loading {
  color: #8a2be2 !important;
  position: absolute;
  top: 65%;
  left: 45%;
  transform: translate(-45%, -65%);
}

#history-user-profile {
  /* height: 29.4531px; */
  height: calc((32.4531 / 1440) * 100vw);
  /* width: 29.4531px; */
  width: calc((32.4531 / 1440) * 100vw);
}

#view-history {
  /* height: 19.4844px; */
  height: calc((21.4844 / 1440) * 100vw);
  /* width: 17.6719px; */
  width: calc((19.4719 / 1440) * 100vw);
}

/*#share-history-loading {
    position: absolute;
}*/

/* Avatars for collaborating users */

.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}

.avatar {
  position: relative;
  overflow: hidden;
}

/* user profile modal */

/* .user-profile {
  height: auto;
  width: 2.0063103953147876vw;
  position: fixed;
  top: 2.8%;
  right: 6.15%;
  margin: 0;
}

#user-profile-image,
#user-profile-home {
  outline: 2px solid #e2522b;
  border-radius: 50%;
} */

/* .user-profile-modal {
  position: absolute;
  top: 4rem;
  right: 6rem;
  height: 13rem;
  width: 17rem;
  border: 1px solid blueviolet !important;
  background-color: rgb(24, 24, 24, 0.95);
  line-height: 0;
  overflow: scroll;
} */

/* .profile-username {
  color: #c4c4c4;
  font-size: 1.15rem;
  font-size: calc((18.4 / 1440) * 100vw);
  margin-left: 1rem;
  text-transform: capitalize;
} */

/* .profile-email {
  color: #c4c4c4;
  font-size: 0.9rem;
  font-size: calc((14.4 / 1440) * 100vw);
  margin-left: 1rem;
  margin-top: -0.5rem;
} */

/* .account-settings,
.sign-out {
  color: #c4c4c4;
  font-size: 1rem;
  opacity: 0.9;
}

.account-settings:hover,
.sign-out:hover {
  opacity: 1;
  cursor: pointer;
} */

/* dropdown */

.dropdown {
  list-style: none;
  position: absolute;
  /* left: 10.5rem; */
  /* background: url(/image/arrow-down.png) no-repeat right rgb(12, 12, 12); */
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  /* width: 16rem; */
  opacity: 0.9;
  /* margin-top: -0.2rem; */
  border-radius: 1vw;
  z-index: 1;
  padding: 0%;
}

.dropdown li {
  /* margin-left: -2rem; */
  cursor: pointer;
  /* text-indent: 1rem; */
}

.dd-items,
.dd-items-alt {
  height: max-content !important;
  display: none;
  list-style: none;
  position: relative;
  top: -100%;
  /* left: 20%; */
  width: calc((140 / 1440) * 100vw);
  /* left: 10.5rem; */
  background: rgb(12, 12, 12);
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  /* width: 16rem; */
  /* margin-top: -0.2rem; */
  border-radius: 1.0416666666666667vw;
  font-size: calc((18 / 1440) * 100vw);
  /* padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; */
  z-index: 2;
  padding: 0%;
  margin-bottom: 0;
}

.dropdown-arrow-eq,
.dropdown-arrow-uneq {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

.dd-items-alt {
  top: -35%;
}

.dd-magic {
  height: 6vh;
  width: 36vw;

  list-style: none;

  position: fixed;
  top: 80.25%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  padding: unset;

  background-color: #181818;
}

.dd-magic .dd-item {
  height: max-content;
  width: max-content;

  cursor: default;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2vw;
}

.dd-magic .dd-item .operations-label {
  font-size: 0.8vw;
  color: #c4c4c4;
  opacity: 0.6;
  text-indent: 0;
  padding: unset;
  margin: unset;
}

/* is(.dd-items, .dd-items-alt) li { */
/* margin-left: -2rem; */
/* cursor: pointer; */
/* padding-top: 0.25rem !important; */
/* padding-bottom: 0.25rem !important; */
/* text-indent: 1rem; */
/* } */

/* .dd-items li:hover, .dd-items-alt li:hover {
  background-color: rgb(77, 26, 127, 0.7);
  border-radius: 1vw;
  color: white;
} */

.export-transcript-selected {
  position: relative;
  width: 24vw !important;
  /* left: 50% !important; */
  text-indent: 0rem !important;
  /* transform: translateX(-50%); */
}

.export-transcript-selected .dd-selected {
  width: 24vw !important;
}

.export-transcript-options {
  width: 24vw !important;
  margin-left: 0% !important;
  margin-bottom: 0% !important;
  margin-top: -4.3%;
  /* left: 50% !important; */
  /* text-indent: 0rem !important; */
  /* transform: translateX(-50%); */
}

.export-trans-action button {
  margin-left: 0 !important;
  /* margin-top: 0 !important; */
}

.export-transcript-filename-input {
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-weight: 900;
  border: 0;
  color: #e2522b;
  /* opacity: 0.8; */
  outline: none;
}

.speaker-profile-input input {
  border-bottom: 1px solid #e2522b;
  width: 20vw;
  text-align: left;
  /* text-indent: 0.5rem; */
}

#koolio-rec-icon,
#koolio-record-icon {
  margin: auto;
}

.animate-play {
  animation: play 1s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
  will-change: transform;
}

@keyframes play {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

#rec-animation,
#record-animation {
  position: absolute;
  left: 50%;
  top: 37%;
  transform: translateX(-50%);
}

#record-animation {
  top: 55%;
}

.recording-duration,
.recording-message {
  opacity: 0.85;
  font-weight: bold;
  color: #c4c4c4;
}

.half-modals {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  color: #c4c4c4;
  z-index: 1031;
  backdrop-filter: blur(1.3177159590043923vw);
}

.half-modals-content {
  background-color: #101010 !important;
  opacity: 0.98;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1011;
  border: solid 1px rgba(196, 196, 196, 0.4);
  max-height: 100vh;
}

.progress-modals {
  position: absolute;
  height: 50%;
  width: 30%;
  margin-top: -270px;
  margin-left: -140px;
  background-color: transparent;
  color: #c4c4c4;
}

.progress-modals-content {
  opacity: 0.98;
  position: absolute;
  width: 300px;
  left: 100%;
  align-items: center;
  top: 100%;
  font-size: 16px;
  transform: translate(-50%, -50%);
}

[id*="_progress"] {
  margin-top: 4px;
}

.progress-style {
  height: 1.3177159590043923vw !important;
  /* width: 100% !important; */
  width: 9.370424597364568vw !important;
  margin: 1%;
  border-radius: 2vw;
  color: var(--var-color-text) !important;
  background: rgba(137, 137, 137, 0.5) !important;
  text-wrap: nowrap;
  position: relative;
}

.progress p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font-size: 24px; */
  font-size: calc((13.25 / 1440) * 100vw);
}

.progress-increment {
  width: 0.2%;
  background: #e2522b !important;
}

#progress-content {
  position: absolute;
  bottom: 70px;
  font-size: 24px;
}

#progress-detail {
  position: absolute;
  margin-top: -60px;
  font-size: 14px;
  color: rgba(137, 137, 137, 0.5) !important;
}

#progress-percentage {
  position: absolute;
  margin-top: -45px;
  font-size: 24px;
  color: #8a2be2;
}

.half-modals-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.select-option {
  background: transparent;
}

.speaker-name {
  position: relative;
  font-weight: bold;
  right: 540px;
  color: #e2522b;
  background: transparent;
}

.half-modals-speaker {
  position: relative;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.half-modals-sub-header {
  opacity: 0.6;
}

#diarization-checkbox {
  cursor: pointer;
}

#reannot-checkbox {
  cursor: pointer;
}

.buzzError {
  opacity: 0.9;
  color: #9c26f6;
  size: 14px;
  visibility: hidden;
}

.half-modals-action {
  display: flex;
}

.sendOtpTimer {
  position: relative;
  top: 20px;
  font-size: 16px;
  margin-left: 10px;
  color: #8a2be2;
}

.pop-up-message {
  display: none;
  background-color: rgba(16, 16, 16, 1);
  position: absolute;
  border: solid 1px rgba(196, 196, 196, 0.3);
  align-items: center;
  justify-self: center;
  z-index: 9999;
  cursor: pointer;
}

.columnMessage {
  width: auto;
}

.pop-up-message-title {
  color: #9c26f6;
  font-weight: 600;
}

.pop-up-message-sub-title {
  color: #c4c4c4;
  opacity: 0.8;
  font-weight: 300;
}

.circles-small {
  margin: 0 auto;
  width: 400px;
  height: 80px;
  text-align: center;
}

.circle-small {
  display: inline-block;
  position: relative;
}

.circle-small .bg {
  fill: none;
  stroke: #e2522b;
  opacity: 0.2;
}

.circle-small .progress {
  fill: none;
  stroke: #e2522b;
  stroke-linecap: round;
  stroke-dasharray: 360;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circle-small .progress.one {
  stroke-dashoffset: 0;
  -webkit-animation: one 30s ease-in;
  animation: one 30s ease-out;
}

#pop-icon {
  position: absolute;
}

@-webkit-keyframes one {
  from {
    stroke-dashoffset: 360;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes one {
  from {
    stroke-dashoffset: 360;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes appear {
  0%,
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0%,
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*SFX Length set before paste-insert*/
.slider {
  position: absolute;
  width: 300px;
  height: 8px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 225px;
  background-color: rgb(47, 47, 47);
  outline: none;
  opacity: 0.7;
  accent-color: #e2522b;
  transition: opacity 0.2s;
  /* -webkit-appearance: none; */
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.5vw;
  height: 1.5vw;
  border-radius: 5px;
  background: #e2522b;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 0.5vw;
  height: 1.5vw;
  border-radius: 5px;
  background: #e2522b;
  cursor: pointer;
}

.slider::-ms-fill-lower {
  background: red;
}

/* .sfx-max-length {
  position: absolute;
  margin-left: 535px;
  color: #9c26f6;
  font-weight: bold;
}

.sfx-min-length {
  position: absolute;
  margin-left: 180px;
  color: #9c26f6;
  text-align: center;
  font-weight: bold;
} */

.sfx-length-input {
  background: #101010;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  border: none;
  padding: 2px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Stripe */
.half-modals-action-button-container {
  margin-top: 1vw;
}

.half-modals-action-button-container .half-modals-action-button {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0px 1vw;
}

:is(.stripe-modal, .subscription-modal) .half-modals-action {
  justify-content: center;
}

#stripe-initiator-modal-header {
  font-size: 1.5vw;
}

.stripe-modal {
  width: unset !important;
}

#payment-form {
  visibility: hidden;
  padding-left: 5vw;
  padding-right: 5vw;
}

/* Show Plan Modal */

.pricing-modal h1 {
  font-size: calc(1rem + 0.5vw);
}

.pricing-modal h4 {
  font-size: calc(0.75rem + 0.4vw);
  overflow-wrap: normal;
}

.pricing-modal .card-header {
  background-color: var(--var-color-primary);
  color: var(--var-color-text);
  padding: 4% 0%;
}
.pricing-modal .card-header h4 {
  font-size: larger;
}

.pricing-modal .card-body-cus {
  background-color: var(--var-color-background);
  padding: 10% 5%;
}

.pricing-modal .card-body-cus ul {
  list-style: initial;
  text-align: left;
  /*padding: 0 10%;*/
}
.card-body-cus ul li {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 7%;
}

#promocode {
  margin-top: 8%;
}

#promocode input {
  background-color: var(--var-color-background-dark);
  color: var(--var-color-text);
  border-radius: 3vw;
  width: 63%;
  border: 1px solid #c4c4c4;
  opacity: 0.8;
  text-align: center;
}

#promocode button {
  border-radius: 3vw;
  width: 30%;
  margin-top: 0%;
  margin-left: 1vw;
  border: 1px solid #c4c4c4;
  opacity: 0.8;
}

/* #promocode button:hover {
    background-color: var(--var-color-secondary);
} */

#trial-month-tagline {
  color: var(--var-color-secondary);
  word-spacing: 0.1em;
  letter-spacing: 0.02em;
}

.card-body-cus button {
  margin-top: 6%;
  padding: 2% 0%;
}

.card-body-cus p {
  margin-top: 6%;
  margin-bottom: 0%;
  font-size: 1 em;
  font-weight: bold;
}

.pricing-modal .card {
  background: none;
  /* border: 1px solid var(--var-color-background); */
  border-radius: 3vw;
}

.pricing-modal .card-title {
  overflow-wrap: initial;
}

.subscription-modal {
  inset: 0;
  margin: auto;
  position: relative;
  transform: unset;
}

body:has(.subscription-modal) {
  overflow-y: hidden;
}

.half-modals:has(.subscription-modal) {
  overflow-y: auto;
}

/* table structure for form */

.form-table > div {
  display: table-row;
}

.form-table > div > * {
  display: table-cell;
  vertical-align: middle;
}

.form-table > div > span {
  padding-right: 2vw;
}

.form-table > div :is(input, textarea, select) {
  width: 100%;
  margin: 1vw 0 1vw;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
}

.form-data-error {
  display: block !important;
  color: var(--var-color-secondary);
  font-size: larger;
  font-weight: bold;
  margin-top: 2vw;
  max-width: fit-content;
  text-align: center;
}

/* .Container_search_in_koolio {
  z-index: 10;
  position: absolute;
} */

/* .search_in_koolio {
  background-color: transparent;
  border: 1px solid #c4c4c4 !important;
  border-radius: 30px;
  text-align: left;
  padding-left: 10px;
  color: white;
  position: relative;
  float: right;
  display: block;
  margin-top: calc((70 / 1440) * 100vw);
  width: calc((((190 / 1440) * 100) * 100vw) / 100);
  border-radius: calc((((20 / 990) * 100) * 100vh) / 100);
  margin-right: calc((((177 / 1440) * 100) * 100vw) / 100);
  font-size: calc((16 / 1440) * 100vw);
} */

/* .search_icon {
  height: 1.8049926793557833vw;
  width: 1.8049926793557833vw;
  position: fixed;
  top: 3.3%;
  right: 14.05%;
  margin: 0;
  cursor: pointer;
} */

/* notification icon */
/* .Container_notification_in_koolio {
  z-index: 20;
  position: absolute;
} */

/* .pending_notification {
  position: absolute;
  display: none;
  float: right;
  left: 1340px;

  margin-top: 5px;
  cursor: pointer;
  background-color: #e2522b;
  border-radius: 100%;
  text-align: center;
  padding-top: 3px;
  color: white;
  font-size: 17px;
  right: 12%;
  top: -18px;
  width: 13.4167px;
} */

/* .pending_notification {
  height: 1.3886383601756955vw;
  width: 1.3886383601756955vw;
  color: white;
  background-color: #e2522b;
  border-radius: 100%;
  font-size: 0.8333308931185944vw;
  line-height: 1.45vw;
  text-align: center;
  display: none;
  position: fixed;
  top: 0.5856515373352855vw;
  left: 87.48169838945827vw;
  margin: 0 0.8784773060029283vw;
} */

/* #notification_icon {
  position: fixed;
  top: 3.4%;
  right: 10.2%;
  cursor: pointer;
  font-size: 1.875vw;
}

.notification_icon_inactive {
  color: white;
}

.notification_icon_active {
  color: #8a2be2 !important;
} */

/* .notification {
  position: fixed;
  display: none;
  float: right;
  top: 4.099560761346998vw;
  right: 5.563689604685212vw;
  height: 22.22159590043924vw;
  width: 18.05453879941435vw;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  opacity: 0.9;
}

.notification-list {
  position: absolute;
  top: -0.1%;
  left: -0.1%;
  margin-right: calc((25 / 1440) * 100vw);
  background-color: transparent;
  height: 300px;
  width: 280px;
  height: calc((300 / 1440) * 100vw);
  width: calc((250 / 1440) * 100vw);
  height: inherit;
  width: inherit;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.notification-list {
  list-style: none;
}

.notification-list li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.notification-list::-webkit-scrollbar {
  display: none;
}

.notification-content {
  position: relative;
  right: 6%;
  text-align: justify;
  height: max-content;
  width: 15.277306002928258vw;
  margin: 0.4rem 0;
  padding-right: 1vh;
  padding-left: 1vh;
  border: none;
  border-radius: 10px;
  color: #c4c4c4;
  background-color: transparent !important;
  cursor: pointer;
  font-size: 0.9027745241581258vw;
} */

/* @media screen and (width >= 1920px) {
  .notification-content {
    right: 2%;
  }
  .pending_notification {
    top: 1vw;
  }
}

.notification-time {
  text-align: left;
  color: #818080;
} */
