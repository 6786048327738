:root {
  --fade-spacing: calc((510 / 100) * 1vw);
  --fade-spacing-center: calc((70 / 100) * 1vw);
}

#top-bar {
  position: fixed;
  top: 0;
}

.playlist-toolbar {
  position: fixed;
}

.sub-menus-section {
  left: 50%;
  top: 82%;
}

.sub-menus-insert,
.sub-menus-fade {
  left: 50%;
  top: 82%;
}

.sub-menus {
  left: 50%;
  top: 82%;
}

.paste-icon {
  color: white;
}

.insert-icon {
  color: white;
}

.paste-icon:hover {
  color: blueviolet;
}

.disabled-icon {
  opacity: 0.5;
  cursor: default;
  transition: all 0.3s;
}
.disabled-spk {
  opacity: 0.5;
  cursor: not-allowed;
}
.preview-control-options {
  position: fixed;
  /* top: 91%; */
  top: 91vh;
  left: 50%;
  transform: translateX(-50%);
  gap: 3.5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
}

#control-mode {
  width: 100%;
  position: fixed;
  top: 81.5%;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.control-options img,
.control-options div {
  position: relative;
  left: -1.5px;
}

#edit-control-options-left {
  position: fixed;
  top: 91vh;
  left: 9.85%;
  gap: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#edit-control-options-right {
  position: fixed;
  top: 91vh;
  right: 8.6%;
  gap: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fade-icons {
  position: fixed;
  width: calc((25 / 1440) * 100vw);
  height: calc((25 / 1440) * 100vw);
  padding: calc((3 / 1440) * 100vw);
}

#undoBtn,
#redoBtn {
  /* width: 1.5vw; */
  /* height: 19px; */
  height: calc((20 / 1440) * 100vw) !important;
  /* width: 18px; */
  width: calc((19 / 1440) * 100vw) !important;
  opacity: 0.2;
}

#undoBtn {
  /* margin-right: 18px; */
  margin-right: calc((19 / 1440) * 100vw);
  /* left: -4px !important; */
  left: calc((-5 / 1440) * 100vw) !important;
}

#redoBtn {
  /* margin-left: 19px; */
  margin-left: calc((20 / 1440) * 100vw);
  /* left: 2px !important; */
  left: calc((2 / 1440) * 100vw) !important;
}

.transcription-area {
  position: fixed;
  width: calc((1180 / 1440) * 100vw);
  display: block;
  top: 12%;
  left: 9%;
}

#modeSep {
  /* height: 20px; */
  height: calc((22 / 1440) * 100vw) !important;
  /* width: 1.5px; */
  width: calc((1.5 / 1440) * 100vw) !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.operations-icon {
  height: 1.8vw;
  width: 1.8vw;
  color: #c4c4c4;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #e2522b;
  }

  &:focus {
    outline: none;
  }

  &.active {
    color: blueviolet;
  }
}

.operations-label {
  font-size: 0.8vw;
  color: #c4c4c4;
  opacity: 0.6;
  padding: unset;
  margin: unset;
  margin-top: 0.6vw;
}

.smaller-operations-icon {
  height: 1.5vw;
  width: 1.5vw;
}
