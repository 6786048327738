.autoLevel-wrapper {
  height: 100vh;
  width: 100vw;
  box-sizing: content-box;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(
    1.3177159590043923vw
  ) !important; /* 18px = 1.3177159590043923vw */
  backdrop-filter: blur(1.3177159590043923vw) !important;
  position: fixed;
  top: 0;
  left: 0;
}

.autoLevel-wrapper .half-modals-content {
  height: 12.545754026354318vw;
  width: 52.49934114202049vw !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.autoLevel-wrapper .half-modals-header {
  height: 1.8747730600292827vw !important;
  width: 48.18696925329429vw !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: absolute;
  top: 2.12298682284041vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.autoLevel-wrapper .half-modals-header p {
  font-size: 1.25vw !important;
  color: #c4c4c4;
}

.autoLevel-wrapper .half-modals-sub-header {
  height: 1.5625036603221085vw;
  width: 48.18696925329429vw !important;
  text-align: left;
  font-size: 1.0416691068814057vw;
  color: #c4c4c4;
  opacity: 0.6;
  position: absolute;
  top: 4.612005856515373vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.autoLevel-wrapper .half-modals-action {
  height: 2.1584480234260615vw;
  width: 48.18696925329429vw !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 8.199121522693996vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.autoLevel-wrapper .half-modals-actions {
  height: inherit;
  width: 15.789751098096632vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
  gap: 1.8vw;
}

.autoLevel-wrapper .half-modals-action-button {
  height: inherit !important;
  width: 5.457313323572474vw !important;
  font-size: 0.972225475841874vw !important;
  margin: 0 !important;
}
