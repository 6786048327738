[class*="ReactModal__"] {
  background-color: unset !important;
}

.gapRemoval-wrapper {
  height: 100vh;
  width: 100vw;
  box-sizing: content-box;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(
    1.3177159590043923vw
  ) !important; /* 18px = 1.3177159590043923vw */
  backdrop-filter: blur(1.3177159590043923vw) !important;
  position: fixed;
  top: 0;
  left: 0;
}

.gapRemoval-wrapper .half-modals-content {
  height: 17.162371888726206vw;
  width: 52.49934114202049vw !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.gapRemoval-wrapper .half-modals-header {
  height: 1.8747730600292827vw !important;
  width: 48.18696925329429vw !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: absolute;
  top: 2.12298682284041vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.gapRemoval-wrapper .half-modals-header p {
  font-size: 1.25vw !important;
  color: #c4c4c4;
}

.gapRemoval-wrapper .half-modals-sub-header {
  height: 1.5625036603221085vw;
  width: 48.18696925329429vw !important;
  text-align: left;
  font-size: 1.0416691068814057vw;
  color: #c4c4c4;
  opacity: 0.6;
  position: absolute;
  top: 4.612005856515373vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.gapRemoval-wrapper .half-modals-speaker {
  height: 1.665446559297218vw !important;
  width: 48.18696925329429vw !important;
  font-size: 1.1111127379209371vw;
  color: #c4c4c4 !important;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  gap: 0.8784773060029283vw;
  position: absolute;
  top: 9.077598828696924vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.gapRemoval-wrapper .half-modals-speaker .sfx-min-length,
.gapRemoval-wrapper .half-modals-speaker .sfx-max-length {
  text-align: center;
  font-size: inherit !important;
  font-weight: 700 !important;
  color: blueviolet;
  background-color: transparent;
  position: static !important;
  margin: 0 !important;
}

.gapRemoval-wrapper .half-modals-speaker .sfx-min-length:focus {
  outline: none;
}

.gapRemoval-wrapper .half-modals-speaker .gapRemoval-slider {
  height: 0.48499267935578333vw;
  width: 19.44428989751098vw;
  border-radius: 5px;
  opacity: 0.8;
  accent-color: #e2522b;
  transition: opacity 0.3s;
}

.gapRemoval-wrapper .half-modals-speaker .gapRemoval-slider:hover {
  opacity: 1;
}

.gapRemoval-wrapper
  .half-modals-speaker
  .gapRemoval-slider::-webkit-slider-thumb {
  width: 0.5vw;
  height: 1.5vw;
  border-radius: 5px;
  background: #e2522b;
  cursor: pointer;
}

.gapRemoval-wrapper .half-modals-speaker .gapRemoval-slider::-moz-range-thumb {
  width: 0.5vw;
  height: 1.5vw;
  border-radius: 5px;
  background: #e2522b;
  cursor: pointer;
}

.gapRemoval-wrapper .half-modals-speaker .gapRemoval-slider:focus {
  outline: none;
}

.gapRemoval-wrapper .half-modals-action {
  height: 2.1584480234260615vw !important;
  width: 48.18696925329429vw !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 12.88433382137628vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.gapRemoval-wrapper .half-modals-actions {
  height: inherit !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
  gap: 1.8vw;
}

.gapRemoval-wrapper .half-modals-actions .half-modals-action-button {
  height: inherit !important;
  width: 5.457313323572474vw !important;
  font-size: 0.972225475841874vw !important;
  margin: 0 !important;
}
