#redirect-page {
  height: 120vh !important;
  width: 100vw !important;
  background-color: #000000;
}
.status-display {
  margin-top: 7.03889px;
  font-size: 7.54167px;
  position: absolute;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 52%;
}
