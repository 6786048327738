.recordingName-wrapper-sfx {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 1px;
  top: 0;
  backdrop-filter: blur(1.3177159590043923vw);
  background-color: rgba(0, 0, 0, 0.5);
  /* z-index: 1000; */
}

#addonSep {
  /* height: 20.375px; */
  height: calc((21.375 / 1440) * 100vw);
  /* width: 1.42188px; */
  width: calc((1.42188 / 1440) * 100vw);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 15%);
}

#annotmodal {
  position: fixed;
  width: 100vw;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.modal-content {
  background-color: transparent;
  margin: auto;
  max-height: 100%;
  text-align: center;
  max-width: 59%;
}
.close-modal {
  right: 23.67px;
  z-index: 1010;
  margin-top: -7rem;
}

.addon-opt-sfx {
  color: rgb(73, 73, 73);
  font-size: 16.9px;
  position: fixed;
}
.addon-opt-music {
  /* color: rgb(73, 73, 73); */
  color: rgb(73, 73, 73);
  font-size: 16.9px;
  position: fixed;
  left: 50%;
}

#sfxSearchBar {
  /* height: 4.2vh; */
  height: calc((29 / 1440) * 100vw);
  width: 22.15vw;
  border: 1px solid white;
  background: transparent;
  align-content: center;
  border-radius: 3rem;
  text-align: center;
  /* font-size: 12px; */
  font-size: calc((15 / 1440) * 100vw);
  font-weight: 900;
  color: white;
  margin-top: 4vh;
  /* margin-bottom: 10vh; */
  margin-bottom: 5vh;
  margin-left: calc((548 / 1440) * 100vw);
}

#sfxSearchBar:focus {
  outline: none !important;
}

#addon-opt-sfx-text,
#addon-opt-music-text {
  /* font-size: 16.9px; */
  font-size: calc((19.6 / 1440) * 100vw);
  cursor: pointer;
  font-weight: 500;
}

#addon-opt-sfx-text {
  position: fixed;
  top: 0;
  left: 44.25vw;
  transform: translateY(15%);
}

#addon-opt-music-text {
  position: fixed;
  top: 50%;
  right: 43.1vw;
  transform: translateY(15%);
}

#music-genres {
  position: relative;
  /* top: 12.7vh; */
  left: 30%;
  transform: translateX(-50%);
  display: flex !important;
  justify-content: center !important;
  width: calc((850 / 1440) * 100vw);
  margin: 0 auto;
  /* border: solid 1px red; */
}

#music-genres .action-project {
  position: relative;
  top: 0;
  left: 0;
  margin: 0 !important;
  width: inherit;
  /* border: solid 1px blue; */
}

.action-project {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.sfxcontent {
  color: #c4c4c4;
  padding: -0.2vw 1vw;
  text-decoration: none;
  text-align: center;
  font-weight: 900;
  display: block;
  width: fit-content;
  margin: auto;
  cursor: pointer;
}

#modal_1 {
  z-index: 1005;
  position: absolute;
  display: block;
}

.action-project button,
#create-music-button {
  background: #0c0c0c !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  /* opacity: 0.8; */
  /* padding: 2.57292px 17.8646px; */
  padding: calc((4.5 / 1440) * 100vw) calc((23.5 / 1440) * 100vw);
  /* margin: 9px 6.54583px; */
  margin: calc((9.5 / 1440) * 100vw) calc((7.5 / 1440) * 100vw);
  /* padding: 9px 14px; */
  /* font-size: 14.5px; */
}
.music-action-project-button {
  font-size: 1.2rem;
}
.action-project button:hover,
#create-music-button:hover {
  background-color: #e2522b !important;
}

.orange-heading {
  color: rgb(226, 82, 43);
}

.purple-heading {
  color: blueviolet;
}

.sfxcontent p {
  position: relative;
  right: 1.15vw;
  transition: color 0.3s;
  font-size: 1.25vw !important;
}

.sfxcontent p:hover {
  color: blueviolet;
}

#close-modal-window {
  height: auto;
  width: 1.4vw !important;
  position: fixed;
  top: 0;
  right: 1.75vw;
  transform: translateY(-16.4vh);
  z-index: 999;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
}

#sfx-music-arrow-right {
  position: absolute;
  top: 21vh;
  right: 10%;
  cursor: pointer;
}
#sfx-music-arrow-left {
  position: absolute;
  left: 10%;
  top: 21vh !important;
  cursor: pointer;
}
