.recordingName-wrapper-transcription {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.3177159590043923vw);
  z-index: 99999;
}

.recordingName-wrapper-transcription .half-modals-content {
  height: 20.46705710102489vw;
  width: 52.49934114202049vw;
  position: relative;
}

.recordingName-wrapper-transcription .half-modals-header {
  height: 1.8747730600292827vw !important;
  width: 48.18696925329429vw !important;
  display: flex;
  justify-content: space-between;
  position: absolute !important;
  top: 2.12298682284041vw !important;
  left: 50% !important;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 1.25vw !important;
  color: #c4c4c4;
  margin: 0 !important;
}

.recordingName-wrapper-transcription .half-modals-sub-header {
  height: 1.5625036603221085vw !important;
  width: 48.18696925329429vw !important;
  position: absolute !important;
  top: 4.612005856515373vw !important;
  left: 50% !important;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.6 !important;
  font-size: 1.0416691068814057vw;
  color: #c4c4c4;
}

.recordingName-wrapper-transcription .export-transcript-filename-cont {
  height: 1.665446559297218vw !important;
  width: 48.18696925329429vw !important;
  position: absolute !important;
  top: 7.906295754026354vw !important;
  left: 50% !important;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 1.1111127379209371vw;
  color: #c4c4c4;
}

.recordingName-wrapper-transcription .export-transcript-select {
  height: 2.021185944363104vw !important;
  width: 48.18696925329429vw !important;
  position: absolute !important;
  top: 11.639824304538799vw !important;
  left: 50% !important;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
}

.recordingName-wrapper-transcription
  .export-transcript-select
  .export-transcript-selected {
  height: inherit;
  width: 23.999121522693994vw;
  font-size: 0.972225475841874vw;
  padding-top: 0.25vw;
  text-indent: 1.25vw !important;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.2);
}

.recordingName-wrapper-transcription .handlebutton {
  height: 2.1584480234260615vw !important;
  width: 8.502269399707174vw !important;
  position: absolute !important;
  top: 16.178623718887263vw !important;
  left: 2.12298682284041vw !important;
  background: #0c0c0c;
  border: 0.1vw solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  opacity: 0.8;
  font-size: 0.972225475841874vw !important;
  margin: 0 !important;
  text-align: center;
}

.export-transcript-select {
  position: relative;
  margin-top: 21.4375px;
  font-size: 10.0042px;
}

.dropdown {
  list-style: none;
  position: absolute;
  /* left: 10.5rem; */
  /* background: url(/static/img/arrow-down.png) no-repeat right rgb(12, 12, 12); */
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  /* width: 16rem; */
  opacity: 0.9;
  /* margin-top: -0.2rem; */
  border-radius: 1vw;
  z-index: 1;
  padding: 0%;
}
