.moreOptionContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  backdrop-filter: blur(1.3177159590043923vw);
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 101;
}
