body {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

* {
  font-family: "Avenir", sans-serif;
}

/* font family */
@font-face {
  font-family: "Avenir";
  src: url("../../fonts/Avenir-Roman.otf");
}

/* style for the upload audio component */
.upload-audio {
  width: 100%;
  margin-top: 5vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

.upload-audio i.audio-icon {
  font-size: 5em;
}

/* the container where wavesurfer adds the audio waveform */
.waveform-container {
  margin: 0 auto;
  /* max-width: 80vw; */
  background-color: #181818;
  height: 100vh;
}

/* style for the navbar */
/* nav {
  display: flex;
  align-items: center;
  height: 3.5em;
  padding: 0 2em;
  background-color: #040404;
  color: white;
  font-size: large;
  font-weight: bold;
} */

nav > .brand {
  display: flex;
  color: white;
  align-items: center;
}

nav a {
  text-decoration: none;
  color: inherit;
}

/* all icons */
i.material-icons {
  font-size: 1.5em;
  color: #f9f8f9;
}

h1 {
  color: #1d0b22;
}

/* style for the upload button */
button.upload-btn {
  width: 10em;
  font-size: 1em;
  padding: 0.5em;
  background: #f37405;
  border-radius: 999px;
  color: white;
  border-color: #d9380f;
  cursor: pointer;
}

button.upload-btn:hover {
  filter: brightness(1.1);
}

/* style for the trim button */
.trim {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #1d0b22;
  color: white;
  width: 5em;
  border-radius: 2px;
  cursor: pointer;
  padding: 0.25em;
  border: 1px solid rebeccapurple;
}

/* all the button controls */
div.all-controls {
  margin: 2em auto;
  max-width: 60vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.all-controls .right-container,
.all-controls .left-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}

button.controls {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.2em;
}

/* style for both sliders */
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: rgba(213, 184, 255, 0.7);
  border: 1px solid #1d0b22;
  border-radius: 999px;
  margin: 0;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  filter: brightness(1.05);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #1d0b22;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #1d0b22;
  cursor: pointer;
  border-radius: 50%;
}

.volume-slide-container {
  display: flex;
  align-items: center;
}

/* smaller icon for zoom slider */
i.zoom-icon {
  font-size: 0.8em;
}

/* footer style */
footer {
  font-family: inherit;
  position: fixed;
  bottom: 2%;
  right: 2%;
}

@media screen and (max-width: 430px) {
  nav {
    font-size: 1em;
  }

  .upload-audio i.audio-icon {
    font-size: 4em;
  }

  /* all icons */
  i.material-icons {
    font-size: 1.5em;
  }

  h1 {
    font-size: 1.5em;
  }

  div.all-controls {
    max-width: 90vw;
    flex-flow: column nowrap;
  }

  .waveform-container {
    max-width: 90vw;
  }

  .all-controls .right-container,
  .all-controls .left-container {
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1em;
  }

  /* smaller icon for zoom slider */
  i.zoom-icon {
    font-size: 1em;
  }
}

/*
  Documentation for this available at:
  https://getbootstrap.com/docs/5.2/components/tooltips/#variables

  Need to use !important to override the defaults
*/
.custom-tooltip {
  --bs-tooltip-bg: #080808 !important;
  --bs-tooltip-font-size: 1vw !important;
  --bs-tooltip-max-width: 20vw !important;
  --bs-tooltip-border-radius: 10px !important;
}

.custom-tooltip > * {
  text-transform: capitalize !important;
}
