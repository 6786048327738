.sfxLength-wrapper {
  height: 100vh;
  width: 100vw;
  box-sizing: content-box;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(
    1.3177159590043923vw
  ); /* 18px = 1.3177159590043923vw */
  backdrop-filter: blur(1.3177159590043923vw);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.sfxLength-container {
  position: fixed;
  z-index: 999991;
}

.sfxLength-container .half-modals-content {
  height: 13.482576866764276vw;
  width: 52.49934114202049vw !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.sfxLength-container .half-modals-header {
  height: 1.8747730600292827vw;
  width: 48.18696925329429vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: absolute;
  top: 2.12298682284041vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
}

.sfxLength-container .half-modals-header p {
  font-size: 1.25vw;
  color: #c4c4c4;
}

.sfxLength-container .half-modals-speaker {
  height: 1.665446559297218vw !important;
  width: 48.18696925329429vw !important;
  font-size: 1.1111127379209371vw;
  color: #c4c4c4 !important;
  box-sizing: content-box;
  position: absolute;
  top: 5.417276720351391vw;
  left: 2.12298682284041vw;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  gap: 0.8784773060029283vw;
}

.sfxLength-container .half-modals-speaker .sfx-min-length,
.sfxLength-container .half-modals-speaker .sfx-max-length {
  text-align: center;
  font-size: inherit;
  font-weight: 700;
  color: blueviolet;
  background-color: transparent;
}

.sfxLength-container .half-modals-speaker .sfxLength-slider {
  height: 0.48499267935578333vw;
  width: 19.44428989751098vw;
  border-radius: 5px;
  opacity: 0.8;
  accent-color: #e2522b;
  transition: opacity 0.3s;
}

.sfxLength-container
  .half-modals-speaker
  .sfxLength-slider::-moz-range-progress {
  height: inherit;
  width: auto;
  border-radius: inherit;
  background: #e2522b;
}

.sfxLength-container .half-modals-speaker .sfxLength-slider:hover {
  opacity: 1;
}

.sfxLength-container
  .half-modals-speaker
  .sfxLength-slider::-webkit-slider-thumb {
  width: 0.5vw;
  height: 1.5vw;
  border-radius: 5px;
  background: #e2522b;
  cursor: pointer;
}

.sfxLength-container .half-modals-speaker .sfxLength-slider::-moz-range-thumb {
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 999px;
  border-color: #e2522b;
  background: #e2522b;
  cursor: pointer;
}

.sfxLength-container .half-modals-speaker .sfxLength-slider:focus {
  outline: none;
}

.sfxLength-container .half-modals-action {
  height: 2.1584480234260615vw !important;
  width: 48.18696925329429vw !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 8.931185944363104vw;
  left: 2.1vw;
  margin: 0 !important;
}

.sfxLength-container .half-modals-actions {
  height: inherit !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
}

.sfxLength-container .half-modals-actions .half-modals-action-button {
  height: inherit !important;
  width: 5.457313323572474vw !important;
  font-size: 0.972225475841874vw !important;
  margin: 0 !important;
}
