#half-modals-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  backdrop-filter: blur(1.3177159590043923vw);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  color: #cfcfcf;
}

#half-modals-content {
  width: calc((755.641 / 1440) * 100vw);
  background-color: #101010 !important;
  opacity: 0.98;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1011;
  border: solid 1px rgba(196, 196, 196, 0.4);
  max-height: 100vh;
  padding: 2%;
  border-radius: 10px;
}
.half-modals-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  /* font-size: 13px; */
  font-size: calc((18 / 1440) * 100vw);
  color: #c4c4c4;
}

#show-notes-confirmation-modal-sub-header,
.half-modals-sub-header {
  font-size: calc((16 / 1440) * 100vw);
  color: #c4c4c4;
  opacity: 0.4;
}

.half-modals-actions {
  width: 100%;
  height: auto;
  display: flex;
  gap: 3.7%; /* 24px */
  margin-top: 12px;
}
.half-modals-actions button,
.half-modals-actions-inputs button {
  background: #0c0c0c;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  opacity: 0.8;
  margin-top: 1rem;
  font-size: calc((14 / 1440) * 100vw);
  padding: 5px 0;
}

.half-modals-actions-inputs button {
  border-radius: 0.7vw;
}

.half-modals-actions button:hover,
.half-modals-action-inputs button:hover {
  background: #e2522b;
  opacity: 1;
  color: #ffffff;
}

.half-modals-actions button {
  /* width: 60% !important; */
  /* width: 73.5156px !important; */
  width: calc((78.7 / 1440) * 100vw) !important;
}

#full-modals-content {
  width: 100%;
  opacity: 0.98;
  position: absolute;
  padding: 2%;
}

.close-btn-container {
  position: relative;
}

.top-right-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px; /* Optional: Adjust for spacing from the top-right corner */
}
.full-modals-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  text-align: center;
}
.full-modal-title {
  font-size: calc((((28 / 990) * 100) * 100vh) / 100);
  color: rgb(138, 43, 226);
  text-align: center;
}
.full-modals-button-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.full-modal-btn {
  font-size: calc((((20 / 990) * 100) * 100vh) / 100);
  width: calc((((160 / 1440) * 100) * 100vw) / 100);
  padding: calc((((7 / 1440) * 100) * 100vw) / 100);
  background: #0c0c0c;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
  opacity: 0.8;
  margin-top: calc((((25 / 1440) * 100) * 100vw) / 100);
  margin-left: calc((((25 / 1440) * 100) * 100vw) / 100);
  margin-right: calc((((25 / 1440) * 100) * 100vw) / 100);
  cursor: pointer;
}
.full-modals-actions button:hover {
  background: #e2522b;
  opacity: 1;
  color: #ffffff;
}
