#genAi-image {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(1.3177159590043923vw);
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 200;
}

.image-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191919;
  border-radius: 0.75vw;
}

.image-modal-container:hover > #closeImageModalIcon,
.image-modal-container:hover > #downloadImageIcon {
  display: block;
}

.image-modal-container:hover .hoverable-image {
  opacity: 0.3;
}

.hoverable-image {
  /* height: 501px; */
  /* height: calc((536 / 1440) * 100vw); */
  height: 79.5vh;
  /* width: 501px; */
  /* width: calc((536 / 1440) * 100vw); */
  width: 79.5vh;
  transition: opacity 0.3s;
  border-radius: 0.75vw;
}

#closeImageModalIcon {
  height: auto;
  /* width: 15px; */
  width: calc((16.05 / 1440) * 100vw) !important;
  display: none;
  position: absolute;
  top: 3%;
  left: 97%;
  transform: translate(-97%, -3%);
  z-index: 999;
  transition: display 0.6s;
}

#downloadImageIcon {
  height: auto;
  /* width: 15px; */
  width: calc((16.05 / 1440) * 100vw) !important;
  display: none;
  position: absolute;
  top: 3%;
  left: 90.8%;
  transform: translate(-90.8%, -3%);
  z-index: 999;
  transition: display 0.6s;
}
