.transcript-loader {
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  width: 23vw;
  transform: translateX(23vw);
  background-size: cover;
  /* z-index: 98 !important; */
  background: rgba(75, 75, 75, 0.1);
  background-position: 100%;
  background-size: 125%;
  -webkit-backdrop-filter: blur(1.3177159590043923vw);
  backdrop-filter: blur(1.3177159590043923vw);
}

.clicked-word {
  color: blueviolet !important;
}

.transcriptSearch {
  height: 4.2vh;
  /* width: 61.5%; */
  background: transparent;
  border: 1px solid white;
  border-radius: 2rem;
  text-align: left;
  opacity: 0.6;
  margin-right: 0.1rem;
  position: relative;
  top: 2.8vh;
  left: 0.9vw;
  color: white;
  /* font-size: 10px; */
  font-size: calc((18 / 1440) * 100vw) !important;
  font-weight: 700;
}

#transcriptIcons {
  position: relative;
  top: 3vh;
  left: 0.75vw;
  gap: 1vw;
  /* background-color: red; */
}

#transcriptIcons img {
  /* height: 16px; */
  height: calc((18 / 1440) * 100vw) !important;
  /* width: 16px; */
  width: calc((18 / 1440) * 100vw) !important;
}

.close-transcript img {
  position: relative;
  top: 2.6vh !important;
  right: 2rem;
  width: calc((20 / 1440) * 100vw) !important;
  /* width: 6%; */
  /* visibility: hidden; */
}

@media screen and (width < 1920px) {
  #transcriptIcons {
    top: 2.4vh;
    left: 0.75vw;
    gap: 0.6vw;
  }
}

.transcript-menu {
  position: fixed !important;
  bottom: 6vh !important;
  left: 50%;
  transform: translateX(-50%);
  /* bottom: -27.5vh; */
  gap: 0.85vw;
  /* background-color: red; */
}

@media screen and (width >= 1920px) {
  .transcript-menu {
    bottom: -50.75vh;
  }
}

.transcript-menu img {
  height: calc((21 / 1440) * 100vw) !important;
  width: calc((21 / 1440) * 100vw) !important;
}

.searchOptions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  top: 0.71rem;
}

.transcript-main {
  /* height: 75vh !important; */
  height: ((((590 / screenHeight) * 100) * 100vh) / 100) + "px" !important;
  top: 5vh;
  padding: 0.3385416666666667vw 0.83740234375vw;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin !important;
}

#transcription-wrapperpara {
  overflow: visible;
  overflow-wrap: break-word;
  word-wrap: break-word;
  position: relative;
  padding: 0.6rem;
}

#transcription-wrapperpara p {
  font-size: 1.07421875vw;
  font-weight: 700;
  padding: 0.5208333333333334vw 0.6510416666666666vw;
  cursor: pointer;
  display: inline-block;
}

#transcription-wrapperpara p span {
  transition: color 0.3s;
}

#transcription-wrapperpara p span:hover {
  color: blueviolet !important;
}
#transcription-wrapperpara-workspace p span:hover {
  color: blueviolet !important;
}

#input-replace-transcript {
  position: absolute;
  top: 6vh;
  margin-top: 5%;
  margin-left: 0.75rem;
  text-indent: 0.3vw;
  padding-left: 0.3vw;
}

.transcriptSearchOptionSpacing-alt-replaceicon {
  top: 2vh;
  left: 80%;
}
.transcriptSearchOptionSpacing-alt-replaceicon img {
  position: absolute;
  top: 0vh !important;
}
#fullTranscript {
  position: relative;
  overflow-y: auto !important;
  top: 14%;
  height: 60vh !important;
  scrollbar-width: none;
}
#multiselect-replace-transcript {
  position: absolute;
  top: 6vh;
  left: 0;
  /* top: 6vh; */
  /* left: 9vw; */
}
/* Chatbot Container */
.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: transparent;
  /* border-radius: 10px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: absolute;
  bottom: 0;
}

/* Chatbot Header */
.chatbot-header {
  background: transparent;
  padding: 15px;
  text-align: left;
}

/* .chatbot-logo {
  height: 40px;
} */

/* Chat Window */
.chat-window {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background: transparent;
}

/* Chat Bubbles */
.chat-bubble {
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 15px;
  max-width: 75%;
  word-wrap: break-word;
}

.chat-user {
  background: blueviolet;
  color: white;
  align-self: flex-end;
  text-align: left;
}

.chat-bot {
  background: white;
  color: black;
  align-self: flex-start;
  text-align: left;
}

/* Chat Input */
.chat-input-container {
  display: flex;
  align-items: flex-end;
  padding: 10px;
  border-top: 1px solid #ccc;
  background: transparent;
  justify-content: space-between;
}

.chat-input {
  flex: 1;
  padding: 8px;
  border: none !important;
  /* margin-right: 8px; */
  min-height: 40px; /* Set a minimum height */
  max-height: 150px; /* Set a max height to prevent excessive expansion */
  overflow-y: auto; /* Enable scrolling if max height is reached */
  resize: none; /* Prevent manual resizing */
}

.chat-send,
.chat-upload {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .chatbot-container {
    height: 100%;
  }

  .chat-bubble {
    max-width: 90%;
  }
}

.chat-wrapper {
  display: flex;
  align-items: flex-end;
  width: 90%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

textarea:focus {
  outline: none;
  border: none; /* Optional: Removes the border as well */
}

.chat-upload {
  padding: 8px;
}
