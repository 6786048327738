.profileModal-wrapper,
#workspace-closing-loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(1.3177159590043923vw);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

#playlist-loader,
#workspace-closing-loader {
  display: none;
}

#playlist-loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

#playlist-loader-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 5vh;
  left: 0;
  background-color: #181818;
}

#playlist-globalLoader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

#playlist-globalLoader-bg {
  height: 86vh;
  width: 97vw;

  position: fixed;
  bottom: 2.5vh;
  left: 0;

  background-color: #181818;
  /* background-color: red; */
}

#annotmodalprofile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  z-index: 999;
}

.user-profile-modal {
  height: 12.305563689604684vw;
  width: 19.930453879941435vw;
  box-sizing: content-box;
  padding-top: 1.2445095168374818vw;
  padding-left: 1.2445095168374818vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 4.026354319180088vw;
  right: 4.904831625183016vw;
}

.user-profile-separator {
  align-self: center;
  height: 1px;
  width: 17.44143484626647vw;
  color: blueviolet;
  margin-top: 1.0248901903367496vw;
  margin-bottom: 1.0248901903367496vw;
  margin-right: 1.2445095168374818vw;
}

.user-details {
  height: auto;
  width: 18.39311859443631vw;
  align-self: flex-end;
}

.user-profile-details {
  display: flex;
  flex-direction: column;
  gap: 0.6vw;
}

#user-profile-image {
  height: auto;
  width: 4.159040995607613vw;
  box-sizing: content-box;
}

.user-profile-details .profile-username {
  font-size: 1.5446559297218154vw;
  margin-top: 7px;
}

.user-profile-details .profile-email {
  font-size: 1.0069472913616397vw;
}

#close-profile-modal-window {
  height: auto;
  width: 1.4343923865300148vw;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-options {
  margin-left: 1.0980966325036603vw;
}

#account-role,
.profile-option {
  font-size: 1.097225475841874vw;
  text-align: left;
  margin-bottom: 0.5124450951683748vw;
}

.profile-option span {
  margin-right: 0.3vw;
}
