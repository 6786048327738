#spk-mute.active {
  color: #8a2be2 !important;
}
#sfx-mute.active {
  color: #8a2be2 !important;
}
#spk-solo.active {
  color: #8a2be2 !important;
}
#spk-solo.active {
  color: #8a2be2 !important;
}

/* Transcript header row */
#transcript-header {
  anchor-name: --transcript-header;
}

/* Transcript sub vol ctrl */

.sub-vol-ctrl-transcript {
  height: fit-content;
  width: fit-content;
  visibility: hidden;
  position: fixed;
  position-anchor: --transcript-header;
  top: calc(anchor(center) - 1.25vw);
  left: anchor(25%);
  backdrop-filter: blur(0.36603221083455345vw);
  -webkit-backdrop-filter: blur(0.36603221083455345vw);
  z-index: 99;
}

@-moz-document url-prefix() {
  .sub-vol-ctrl-transcript {
    /* top: 91px; */
    top: 5.688rem;
    /* right: 313px; */
    right: 20.377604166666668vw;
  }
}

.sub-vol-ctrl-transcript .sub-vol-ctrl-cont-transcript {
  height: 2.5vw;
  width: 10vw;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-vol-ctrl-cont-transcript .sub-vol-ctrl-range-transcript {
  height: 0.36603221083455345vw;
  width: 6.5vw;
  background: linear-gradient(
    to left,
    rgba(226, 82, 43, 0.4),
    rgba(138, 43, 226, 0.3)
  );
  appearance: none;
  -webkit-appearance: none;
  border-radius: 999px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
}

.sub-vol-ctrl-cont-transcript .sub-vol-ctrl-range-transcript:hover {
  opacity: 1;
}

.sub-vol-ctrl-cont-transcript .sub-vol-ctrl-range-transcript:focus {
  outline: none;
}

.sub-vol-ctrl-cont-transcript
  .sub-vol-ctrl-range-transcript::-webkit-slider-thumb {
  height: 1.5vw;
  width: 0.5vw;
  background: linear-gradient(to bottom, #e2522b, #8a2be2);
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.36603221083455345vw;
  box-shadow: 0 0 18px 0 #000;
  cursor: pointer;
}

.sub-vol-ctrl-cont-transcript .sub-vol-ctrl-range-transcript::-moz-range-thumb {
  height: 1.5vw;
  width: 0.5vw;
  background: linear-gradient(to bottom, #e2522b, #8a2be2);
  border: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.36603221083455345vw;
  box-shadow: 0 0 18px 0 #000;
  cursor: pointer;
}

.sub-vol-ctrl-cont-transcript .sub-vol-ctrl-value {
  height: fit-content;
  width: 4vw;
  font-size: 1.25vw;
  font-weight: 700;
  text-align: center;
  color: white;
}

/* Audio sub vol ctrl */

#sub-vol-ctrl-spk {
  top: 0.8vw;
}

#sub-vol-ctrl-sfx {
  top: 10.4vw;
}

@media screen and (width > 1920px) {
  #sub-vol-ctrl-spk {
    margin-top: 0.8vw;
  }

  #sub-vol-ctrl-sfx {
    margin-top: 4.4vh;
  }
}

.sub-vol-ctrl {
  height: fit-content;
  width: fit-content;
  visibility: hidden;
  position: absolute;
  left: 0;
  backdrop-filter: blur(0.36603221083455345vw);
  -webkit-backdrop-filter: blur(0.36603221083455345vw);
  z-index: 99;
}

.sub-vol-ctrl .sub-vol-ctrl-cont {
  height: 6.5vw;
  width: 8vw;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-vol-ctrl-cont .sub-vol-ctrl-range {
  height: 0.36603221083455345vw;
  width: 6.5vw;
  background: linear-gradient(
    to left,
    rgba(226, 82, 43, 0.4),
    rgba(138, 43, 226, 0.3)
  );
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 999px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
  margin: 0 -1vw;
}

.sub-vol-ctrl-cont .sub-vol-ctrl-range:hover {
  opacity: 1;
}

.sub-vol-ctrl-cont .sub-vol-ctrl-range:focus {
  outline: none;
}

.sub-vol-ctrl-cont .sub-vol-ctrl-range::-webkit-slider-thumb {
  height: 1.5vw;
  width: 0.5vw;
  background: linear-gradient(to bottom, #e2522b, #8a2be2);
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.36603221083455345vw;
  box-shadow: 0 0 18px 0 #000;
  cursor: pointer;
}

.sub-vol-ctrl-cont .sub-vol-ctrl-range::-moz-range-thumb {
  height: 1.5vw;
  width: 0.5vw;
  background: linear-gradient(to bottom, #e2522b, #8a2be2);
  border: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.36603221083455345vw;
  box-shadow: 0 0 18px 0 #000;
  cursor: pointer;
}

.sub-vol-ctrl-cont .sub-vol-ctrl-value {
  height: fit-content;
  width: 4vw;
  font-size: 1.25vw;
  font-weight: 700;
  text-align: center;
  color: white;
}

.volume-range {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* WebKit */
  appearance: slider-vertical;
  padding: 0;
  width: 8px;
  height: 19vh;
}

#playlistarea {
  position: fixed;
  height: calc((300 / 1440) * 100vw);
  width: calc((1450 / 1440) * 100vw);
  top: 18%;
}

.col-8-mod {
  width: 58% !important;
}

.spk-track-controls.col-1-mod {
  width: 16% !important;
}
.playlist-area .spk-track-controls .add-spk-sfx {
  position: relative;
  display: -webkit-box;
  top: 33%;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 0;
}

.post-content {
  z-index: 11000;
  position: relative;
}

.playlist-area .spk-track-controls .sfx-slider-cont .prev {
  position: absolute;
  left: 31%;
  margin-top: 0.25vmax;
  cursor: pointer;
}

.playlist-area .spk-track-controls .sfx-slider-cont .next {
  position: absolute;
  right: 31%;
  margin-top: 0.25vmax;
  cursor: pointer;
}

.prev-transcript,
.next-transcript {
  cursor: pointer;

  margin-top: 1vh;

  --x-margin: 1.4vw;

  margin-left: var(--x-margin);
  margin-right: var(--x-margin);
}

#sfx-prev,
#sfx-next {
  height: 2.3696682464454977vh !important;
  width: 0.6588579795021962vw !important;
}

#transcriptSFXSliderBox .sfx-slider-cont .prev {
  position: absolute;
  left: 12%;
  margin-top: 0.4vmax;
  cursor: pointer;
}

#transcriptSFXSliderBox .sfx-slider-cont .next {
  position: absolute;
  right: 12%;
  margin-top: 0.4vmax;
  cursor: pointer;
}

@media screen and (width < 1920px) {
  .playlist-area .spk-track-controls .sfx-slider-cont .prev,
  .playlist-area .spk-track-controls .sfx-slider-cont .next {
    margin-top: 0.35vmax;
  }
}

.playlist-area .spk-vol-ctrl .vol-ctrl-elem .speakers-vol,
.playlist-area .spk-vol-ctrl .vol-ctrl-elem .sfx-vol {
  margin-left: 0.8vmax;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.playlist-area .spk-vol-ctrl .vol-ctrl-elem .speakers-mute,
.playlist-area .spk-vol-ctrl .vol-ctrl-elem .sfx-mute {
  cursor: pointer;
  font-weight: 900;
  margin-left: 1.5vmax;
  opacity: 1 !important;
  display: inline-table;
}

.playlist-area .spk-vol-ctrl .vol-ctrl-elem .speakers-solo,
.playlist-area .spk-vol-ctrl .vol-ctrl-elem .sfx-solo {
  cursor: pointer;
  font-weight: 900;
  margin-left: 1.5vmax;
  opacity: 1 !important;
  display: inline-table;
}

.playlist-area .spk-vol-ctrl .sfx-volume {
  position: absolute !important;
}

.playlist-area .spk-vol-ctrl {
  position: absolute;
  top: -0.8vh;
  gap: 12px;
}

.playlist-area .spk-vol-ctrl .ctrl-vol {
  left: 1.2vw;
  margin-top: 5vh;
  margin-bottom: 14vh;
}

.sfx-ctrl-vol {
  left: 1.2vw;
  margin-top: 5vh;
}

.playlist-wrapper {
  position: relative;
  scrollbar-width: thin;
  /* height: calc((270/1440)*100VW); */
}

.playlist .playlist-wrapper {
  scrollbar-color: #b4b4b4 #181818;
}
.playlist .playlist-wrapper::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #181818;
}

#record-global-playlist {
  overflow: hidden !important;
}

#global-playlist {
  overflow: hidden !important;
  width: calc(58% + 0.3vw);
}

.playlist .playlist-wrapper::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

#global-view {
  position: fixed;
  width: 100%;
  top: 66.4%;
  /* top: calc((450/1440)*100vw); */
}

.vertical-center-left {
  position: absolute;
  top: 23%;
  left: 11%;
  color: #777777;
}

.vertical-center-right {
  position: absolute;
  top: 23%;
  right: 11%;
  color: #777777;
}

.global-view {
  position: relative;
  height: 10vh !important;
  margin: 0;
  padding: 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  -o-border-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.2) -5%,
      white 50%,
      rgba(0, 0, 0, 0.2) 105%
    )
    100% 0 100% 0/1.4px 0 1.4px 0 stretch;
  border-image: -webkit-gradient(
      linear,
      right top,
      left top,
      color-stop(-5%, rgba(0, 0, 0, 0.2)),
      color-stop(50%, white),
      color-stop(105%, rgba(0, 0, 0, 0.2))
    )
    100% 0 100% 0/1.4px 0 1.4px 0 stretch;
  border-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.2) -5%,
      white 50%,
      rgba(0, 0, 0, 0.2) 105%
    )
    100% 0 100% 0/1.4px 0 1.4px 0 stretch;
  z-index: 0;
}

.global-view .playlist .cursor {
  background: #e2522b !important;
}

.playlist-top-bar {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transcript {
  position: absolute !important;
  z-index: 0;
  top: 32%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  right: -0.8%;
  cursor: pointer;
  font-weight: 900;
  font-size: calc((20.2 / 1440) * 100vw) !important;
}

.transcript-loader .transcript-header {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transcript-loader .transcript-header .trans-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.transcript-loader .transcript-main {
  position: relative;
  overflow-y: auto;
}

/* .transcript-main {
  scrollbar-width: none;
}

.transcript-main::-webkit-scrollbar {
  display: none;
  width: 10px;
} */

.transcript-main::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.transcript-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(20, 19, 19);
  border-radius: 10px;
}

.transcript-loader .transcript-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

#control-opt-preview {
  color: blueviolet;
  position: relative;
  /* right: 10px; */
  /* right: calc((11 / 1440) * 100vw) !important; */
  /* left: -16px !important; */
  left: calc((-17 / 1440) * 100vw) !important;
  font-weight: 700;
  letter-spacing: 0.02rem !important;
  cursor: pointer;
  /* font-size: 19px; */
}

#control-opt-edit {
  position: relative;
  /* left: 11px; */
  left: calc((12 / 1440) * 100vw) !important;
  /* color: #494949; */
  font-weight: 700;
  letter-spacing: 0.02rem !important;
  cursor: pointer;
  /* font-size: 18px; */
}

.control-separator {
  opacity: 0.6;
}

.addon-separator {
  opacity: 0.6;
}

.control-undo,
.control-redo {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.disabled-ctrl {
  display: none !important;
}

sup {
  color: #e2522b;
}

.sfx-text {
  color: blueviolet;
  /* font-size: 16px; */
  font-size: 1.1067708333333333vw;
  font-weight: 900;
  font-weight: bold;
}

/* .slider-text {
  margin-left: 1vw;
  margin-right: 1vw;
} */

/* Playlist Area */
.speakers-sliders {
  width: calc((35 / 1440) * 100vw);
}

/* .sfx-sliders {
  width: 2vw !important;
  gap: 1vw;
} */

.speakers-sliders,
.sfx-sliders {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  outline-style: none;
  /* max-width: 30%; */
  /* width: 93.2344px; */
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  transition: left 0.1s linear;
}

.speakers-sliders::-webkit-scrollbar,
.sfx-sliders::-webkit-scrollbar {
  display: none;
}

.speakers-sliders div {
  transition: all 0.3s ease;
}

#speakers-sliders {
  top: 5.4vh;
  left: calc((100 / 1440) * 100vw);
  display: flex !important;
  align-items: center;
}

#sfx-slider-cont {
  height: auto;
  width: 15.799999999999999vw;
  position: absolute;
  top: 61.8%;
  right: 1%;
  justify-content: center;
}

@media screen and (width >= 1920px) {
  #sfx-slider-cont {
    margin-top: 0.15vw;
  }
}

#track-controls {
  position: relative;
}

#add-spk-sfx-tracks {
  height: calc((12 / 1440) * 100vw) !important;
  width: calc((12 / 1440) * 100vw) !important;
  position: absolute !important;
  left: 0;
  cursor: pointer;
  bottom: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

#add-spk-sfx {
  width: fit-content !important;
  top: 13.45vh !important;
  margin-left: 47.5%;
  z-index: 99 !important;
  rotate: 0deg;
  transition:
    margin-left 0.8s,
    rotate 0.6s;
  visibility: hidden;
}

.clicked-annot {
  color: rgb(138, 43, 226) !important;
}
.box-shadow {
  padding: 2px;
  border: 1px solid blueviolet;
  box-shadow: 0px 0px 5px blueviolet;
}

.inactive-annot {
  color: rgba(226, 82, 43, 0.6);
}
.silenced-annot {
  color: #818181;
}
.active-annot {
  color: white;
}

[id*="spk_"] {
  /* font-size: 14px !important; */
  font-size: calc((14 / 1440) * 100vw) !important;
  font-weight: 600 !important;
}

.overflow-annot {
  overflow-x: hidden;
  text-overflow: ellipsis;
  /* background-color: rgba(0, 0, 255, 0.2); */
}

.overflow-annot:hover {
  overflow-x: visible;
}

.non-overflow-annot {
  overflow-x: visible;
  /* color: red !important; */
}

.slider-dp {
  opacity: 0.1 !important;
  /* transition: 0.2s ease-out; */
  text-align: center;
  outline-style: none;
  transform: scale(0.8);
}

.slick-current {
  opacity: 1 !important;
  /* transition: 0.2s ease-out; */
  transform: scale(1);
  outline-style: none;
}

[id*="-slider-spk"] {
  /* height: 31px; */
  height: calc((33.25 / 1440) * 100vw);
  /* width: 31px; */
  width: calc((33.25 / 1440) * 100vw);
}

/* .slick-current > a > img {
    border: 3px solid blueviolet;
} */

.spk-slid {
  margin: auto;
  outline: none;
}

#transcription-wrapperpara [id*="-spk"],
#transcription-wrapperpara-workspace [id*="-spk"] {
  height: 2.0182291666666665vw;
  width: 2.0182291666666665vw;
}

.sliders-track {
  background-color: transparent;
  position: relative;
  color: #fff;
  /* margin-bottom: 20px; */
  padding-left: 0.6rem;
  display: flex;
  align-items: center;
}

a {
  outline-style: none;
}

.ctrl-vol {
  display: flex;
}

#speaker-volume {
  position: relative;
  top: 1.85vh;
  /* background-color: red; */
  /* margin-top: 24%; */
}

#sfx-volume {
  position: relative;
  bottom: 11.4vh;
  /* background-color: red; */
}

.sfx-volume {
  position: relative;
  /* margin-top: 21%; */
}

#reveal-zoom-controls {
  position: fixed;
  top: 35.2vh;
  right: 13.2vw;
  color: white !important;
  font-size: calc((12 / 1440) * 100vw) !important;
  transition: all 0.3s;
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

#zoom-wrapper {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  background-color: transparent;
  visibility: hidden;
}

@media screen and (width >= 1920px) {
  #sfx-volume {
    bottom: 11.8vh;
  }
  #reveal-zoom-controls {
    /* top: 34.9vh; */
    right: 15.2vw;
  }
}

#zoom-control {
  position: fixed;
  top: 34.7vh;
  right: 12.45vw;
  /* transform: translateY(-50%); */
  gap: 1.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 999;
  background-color: transparent;
  /* background-color: red; */
}

#zoom-in,
#zoom-out,
#zoom-reset {
  /* height: 9px; */
  /* height: calc((7.25 / 1440) * 100vw) !important; */
  /* width: 11px; */
  /* width: calc((10 / 1440) * 100vw) !important; */
  font-size: calc((12 / 1440) * 100vw) !important;
}

/* Waveforms */

#playlist > *,
#playlist-wrapper {
  height: 42.2vh !important;
}

#playlist wave {
  height: 16vh !important;
  opacity: 0;
  transition: opacity 1.3s;
  /* height: calc((107 / 1440) * 100vw) !important; */
}

#playlist wave:hover {
  background-color: rgba(255, 255, 255, 0.01);
  transition: background-color 0.3s;
}

#globalview wave {
  /* height: 30px; */
  /* height: 2.1961932650073206vw !important; */
  height: 4.5vw !important;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  opacity: 0;
  transition: opacity 1.3s;
}

#globalview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 58.6vw !important;
  margin-left: 0.4vw;
  /* background-color: red; */
}

/* sfx slider */

.slick-track {
  text-align: center;
}

.slick-slide.selected a span.text,
.slider-sfx,
.slick-current {
  /* border: none; */
  transform: none !important;
  outline: none;
}

.slick-next {
  left: 70% !important;
  top: 45%;
}

.slick-prev {
  left: 20% !important;
  top: 45%;
  z-index: 99;
}

#add-tracks-slider {
  height: 98.78%;
  width: 21vw;
  left: -300%;
  background: linear-gradient(to right, #212121, #181818 80%);
  background-position: 100%;
  background-size: 125%;
  z-index: 10;
  overflow: hidden;
  transition: 0.3s;
}

#add-tracks-slider > div {
  width: fit-content !important;
  margin-top: 6.65vh;
  margin-bottom: 0 !important;
  margin-left: 0.7325vw;
  padding: 0 !important;
  font-size: 2.3vh;
  cursor: pointer;
  transition: 0.3s;
}

#add-tracks-slider > div:hover {
  color: blueviolet;
}

#add-tracks-slider-transcript {
  height: 100vh;
  width: 100vw;
  font-weight: 900;

  visibility: hidden;
  opacity: 0;

  position: fixed;
  top: 0;
  left: 0;

  background: rgba(12, 12, 12, 0.75);
  backdrop-filter: blur(0.7325vw);
  -webkit-backdrop-filter: blur(0.7325vw);

  z-index: 999;
  overflow: hidden;
  transition: 0.3s;
}

#add-tracks-slider-transcript > div {
  height: max-content;
  width: max-content;

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  transition: 0.3s;
}

#add-tracks-slider-transcript > div > div {
  text-align: center;
  width: fit-content !important;

  display: flex;
  align-items: center;
  justify-content: center;

  /* margin-top: 6.65vh; */
  /* margin-bottom: 0 !important; */
  /* margin-left: 0.7325vw; */
  margin: 0 !important;
  padding: 0 !important;
  font-size: 2.3vh;
  cursor: pointer;
  transition: 0.3s;
}

#add-tracks-slider-transcript > div > div:hover {
  color: blueviolet;
}

/* volume controls */

.vol-ctrl-menu,
.vol-ctrl-menu-transcription {
  height: 38%;
  width: 9vw;
  position: fixed;
  z-index: 99;
  top: 15%;
  right: 0;
  background-position: 100%;
  background-size: 125%;
  background-image: url(../../image/workspace/controls/bgTrackSlider.png);
  /* background: rgba(48, 48, 48, 0.2); */
}

.amplitude-container {
  position: absolute;
  background: #2f2f2f;
  height: 50%;
  width: 30%;
  bottom: 18%;
  left: 20%;
}

.amplitude-way-one {
  width: 30%;
  height: 60%;
  left: 18%;
  position: absolute;
  bottom: 0%;
  background: linear-gradient(180deg, #e2522b 0%, rgba(196, 196, 196, 0) 100%);
}

.amplitude-way-two {
  width: 30%;
  height: 80%;
  position: absolute;
  bottom: 0%;
  right: 18%;
  background: linear-gradient(180deg, #e2522b 0%, rgba(196, 196, 196, 0) 100%);
}

.volume-container {
  right: 44%;
  position: absolute;
  background: #2f2f2f;
  height: 50%;
  width: 10%;
  bottom: 18%;
}

.mute-solo-opt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mute {
  position: absolute;
  bottom: 0%;
  left: 10%;
  font-size: 1.2rem;
  font-weight: 900;
  color: #c4c4c4;
}

.solo {
  left: 48%;
  position: absolute;
  bottom: 0%;
  font-size: 1.2rem;
  font-weight: 900;
  color: #c4c4c4;
}

.ui-slider-range-min {
  position: absolute;
  width: 100%;
  background: #9c26f6;
  border: none;
  outline: none;
  bottom: 0%;
}

.knob,
.knob-clean {
  position: absolute;
  top: 7.5%;
  margin: auto;
  width: 3.2rem;
  height: 3.2rem;
}

.knob .knob-knob {
  transition:
    width 0.2s,
    height 0.2s;
  background-color: #111 !important;
  border: 2px solid #222;
  box-shadow:
    0px 0px 20px rgba(0, 0, 0, 1),
    0px 10px 5px rgba(0, 0, 0, 0.7),
    inset 0px 10px 10px rgba(255, 255, 255, 0.05);
}

.knob .knob-stroke {
  transition: stroke-width 0.2s;
}

.knob-stroke {
  fill: transparent !important;
  stroke: #e2522b !important;
  stroke-width: 11% !important;
}

.knob.grab .knob-stroke {
  stroke-width: 11% !important;
}

.knob .knob-pointer {
  background: #3ff !important;
}

.knob .knob-knob::before {
  position: absolute;
  left: 10%;
  top: 10%;
  width: 70%;
  height: 70%;
  content: " ";
  background: linear-gradient(
    -25deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.07)
  );
  z-index: 3;
  filter: blur(1px);
  border-radius: 100%;
}

.knob.grab .knob-knob {
  width: 80% !important;
  height: 80% !important;
}

.knob .knob-pointer {
  box-shadow:
    inset 0px 0px 5px rgba(0, 0, 0, 1),
    0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

.knob-knob {
  /* height: 100% !important; */
  width: 100% !important;
  background: transparent !important;
}

.knob-pointer {
  top: 0% !important;
  height: 45% !important;
  width: 4% !important;
  background-color: #9c26f6 !important;
}

.knob-outline {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -9999;
  top: 7.1%;
  z-index: 50 !important;
}

.knob-svg {
  position: absolute;
  z-index: 60 !important;
}

.transcriptSearchbar {
  border: 1px solid white;
  width: 8vw;
  background: transparent;
  border-radius: 2rem;
  text-align: center;
  font-size: 10px;
  opacity: 0.6;
  margin-right: 0.1rem;
  height: 4vh;
  color: white;
  font-weight: 700;
}

.replace-transcript-workspace {
  height: 4.2465753424657535vh !important;
  width: max-content;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 85%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (width < 1280px) {
  .replace-transcript-workspace {
    left: 20vw;
  }
}

.transcriptSearch-workspace {
  height: 4.2vh;
  background: transparent;
  border: 1px solid white;
  border-radius: 2rem;
  text-align: left;
  text-indent: 0.5vw;
  opacity: 0.6;
  color: white;
  font-size: calc((18 / 1440) * 100vw) !important;
  font-weight: 700;
}

.transcriptSearch-img {
  height: 3vh !important;
  width: 14.2917px;
  margin-right: 0.1rem;
  position: relative;
  left: 11px;
}

.transcriptSearch-img-new {
  height: 3vh !important;
  width: 14.2917px;
  margin-right: 0.1rem;
  position: relative;
  /* left: 11px; */
}

.searchOptions,
.searchOptions-replace {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.wavesurfer-handle {
  background-color: #e2522b !important;
}

.wavesurfer-region {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

#global-playlist-time > span,
#global-playlist-duration > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: calc((16 / 1440) * 100vw) !important;
  font-weight: 600;
}

#playlist-transcript {
  /* height: 261.969px; */
  /* height: calc((275.969 / 1440) * 100vw) !important; */
  height: 40.1vh !important;
  /* width: 1092.8px; */
  /* width: calc((1151.8 / 1440) * 100vw) !important; */
  width: 80vw !important;
  position: fixed;
  top: 18.8vh;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: rgba(0, 255, 0, 0.05); */
}

#fullTranscript-workspace {
  /* height: 232.172px; */
  /* height: calc((245.172 / 1440) * 100vw) !important; */
  height: 35.55vh !important;
  /* width: 1072.3px; */
  /* width: calc((1130.3 / 1440) * 100vw) !important; */
  width: 78.5vw !important;
  position: relative;
  top: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 0;
  /* background-color: rgba(255, 0, 0, 0.1); */
}

#fullTranscript-workspace::-webkit-scrollbar {
  visibility: hidden;
}

#fullTranscript-workspace span {
  /* font-size: 14.511px; */
  font-size: calc((15.3 / 1440) * 100vw) !important;
  font-weight: 700 !important;
  transition: color 0.3s;
}

#fullTranscript-workspace span:hover {
  color: blueviolet;
}

.transcript-header-workspace {
  /* height: 31.5625px !important; */
  height: 4.85vh !important;
  /* width: 1072.3px !important; */
  width: 78.5vw !important;
  position: fixed !important;
  top: 12.7vh !important;
  left: 50% !important;
  transform: translateX(-50%);
  display: flex !important;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  /* gap: 44.5px; */
  gap: 3.25vw;
  /* background-color: rgba(0, 0, 255, 0.2); */
}

/* .transcript-header-workspace > * {
  border: 1px solid red;
} */

#transcriptDrivenCustomClassesCont {
  height: 4.25vh !important;
}

#add-spk-sfx-tracks-transcript {
  height: 1.25rem;
  width: 1.25rem;
  transition: 0.3s;
}

.add-spk-sfx-transcript {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

#transcriptSearch-workspace {
  height: 4.85vh !important;
  display: flex !important;
  justify-content: flex-start !important;
  /* border: 1px solid red; */
}

#transcriptIcons-workspace {
  height: 4.25vh !important;
  position: absolute !important;
  top: 50% !important;
  left: 44%;
  transform: translateY(-50%);
  gap: 0.75vw;
  /* border: 1px solid red; */
}

#transcriptIcons-workspace img {
  /* height: 19px; */
  height: calc((20 / 1440) * 100vw) !important;
  /* width: 19px; */
  width: calc((20 / 1440) * 100vw) !important;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}

#input-search-transcript-workspace {
  /* height: 27.5938px; */
  height: 4.25vh !important;
  /* width: 104.344px; */
  width: 7.6vw !important;
  text-indent: 0.4vw;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0.6;
  background: transparent;
  border: 1px solid white;
  border-radius: 2rem;
  text-align: left;
  color: white;
  font-size: calc((18 / 1440) * 100vw) !important;
  font-weight: 900;
}

#input-search-transcript:hover,
#input-search-transcript:focus {
  opacity: 1;
  outline: none;
}

#transcriptDrivenButtons {
  height: 4.25vh !important;
  display: flex;
  align-items: center !important;
  gap: 0.95vw;
  /* border: 1px solid red; */
}

#transcriptDrivenButtons img {
  height: calc((20 / 1440) * 100vw) !important;
  width: calc((20 / 1440) * 100vw) !important;
}

#transcript-toggle-t-spk,
#transcriptDrivenButtons .btn-trans-download,
#transcriptDrivenButtons .btn-trans-export {
  height: calc((20 / 1440) * 100vw) !important;
  width: calc((20 / 1440) * 100vw) !important;
  display: flex;
  align-items: center !important;
  margin: 0 !important;
  /* background-color: red; */
}

.btn-trans-export {
  margin-right: 2px !important;
}

#menu-vol-ctrl-spk-transcript {
  height: 4.25vh !important;
  position: absolute;
  top: 50%;
  left: 56.6%;
  transform: translateY(-50%);
  display: flex !important;
  align-items: center !important;
}

#spk-volume-val-transcript {
  height: fit-content !important;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  font-size: calc((19 / 1440) * 100vw) !important;
  margin-left: 0.3vw;
  /* border: 1px solid red; */
}

#speakers-sliders-transcript {
  height: 4.25vh !important;
  width: calc((30 / 1440) * 100vw);
  position: absolute !important;
  top: 50%;
  left: 41%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  overflow-y: hidden;
}

#speakers-sliders-transcript [id*="-slider-spk"] {
  /* height: 31px; */
  height: calc((26.25 / 1440) * 100vw);
  /* width: 31px; */
  width: calc((26.25 / 1440) * 100vw);
}

#sfx-slider-cont-transcript {
  width: calc((232 / 1440) * 100vw);
  position: absolute;
  top: 50%;
  left: 20.2%;
  transform: translateY(-50%);
  /* border: 1px solid red; */
}

#speakerPulsate,
#sfxPulsate {
  height: 30%;
  width: 100%;
  position: absolute;
  background: rgba(100, 100, 100, 0.1);
  z-index: 99;
  opacity: 1;
  transition: opacity 0.9s;
  animation: pulsate 1.5s infinite;
}

#globalViewPulsate {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(100, 100, 100, 0.1);
  z-index: 99;
  opacity: 1;
  transition: opacity 0.9s;
  animation: pulsate 1.5s infinite;
}

#speakerPulsate {
  top: 0.9vw;
}

#sfxPulsate {
  top: 10.2vw;
}

@media screen and (width >= 1920px) {
  #speakerPulsate {
    top: 4.5%;
  }

  #sfxPulsate {
    top: 53.5%;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
}

/* Fade Removal Button */
[id*="fade_btn_"] {
  height: max-content;
  width: max-content;
  color: #c4c4c4;
  font-size: 0.78125vw;
  background-color: #0c0c0c;
  padding: 0.2vw 0.4vw;
  border: 1px solid #c4c4c4;
  border-radius: 2vw;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition:
    background-color 0.3s,
    opacity 0.4s;
  z-index: 1000;
}

#waveform:has([id*="fade_btn_"]:hover) [id*="fade_btn_"],
#sfxarea:has([id*="fade_btn_"]:hover) [id*="fade_btn_"] {
  background-color: initial;
  opacity: 0;
}

#waveform:has([id*="fade_btn_"]:hover) [id*="fade_btn_"]:hover,
#sfxarea:has([id*="fade_btn_"]:hover) [id*="fade_btn_"]:hover {
  background-color: #e2522b;
  opacity: 1;
}

#waveform:hover [id*="fade_btn_"],
#sfxarea:hover [id*="fade_btn_"] {
  opacity: 1;
}

#zoom-container {
  color: white;
  cursor: pointer;
}
