.recordingSpace {
  height: 100vh;
  background-color: #181818;
}
.nested-recording-content {
  position: relative;
  backdrop-filter: blur(1.3177159590043923vw);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.recorder-options-container {
  position: fixed;
  bottom: 5%;
}

.recorder-options {
  /* display: flex; */
  width: 100vw;
  display: flex;
  justify-content: center;
}

.recorder-options > img {
  object-fit: contain;
  height: 1.1458333333333333vw !important;
  width: 1.1458333333333333vw !important;
}

.recordspace-options {
  margin: 1% 2%;
  cursor: pointer;
}

.record-headline {
  display: flex;
  justify-content: center;
  color: #e2522b;
  font-weight: 300;
  font-size: 20px;
}
.recordSpace-logo-container {
  position: relative;
  top: 40%;
  width: 100%;
}

.waveform-recording {
  /* visibility: hidden; */
  /* position: relative;
    top: 20%; */
  height: 20vh;
  /* width: 100vw; */
  position: relative;
  top: 24%;
}

.wavesurfer-recording-container {
  visibility: hidden;
  /* overflow: hidden; */
  /* display: flex; */
  /* width: 60vw; */
  position: relative;
  /* top: -20vh; */
}
.wavesurfer-recording-user {
  display: flex;
  width: 30%;
  align-items: center;
}

.record-user-logo {
  position: relative;
  top: 24vh;
  right: 2vh;
}
/* #wavesurfer-recording{
    overflow: hidden;
    height: 40vh;
    display: flex;
    border-right: 2px solid red;
    border-left: 2px solid red;
    align-items: center;
    width: 60vw;
    margin: auto;
} */

.koolio-recording-logo {
  display: flex;
  justify-content: center;
  /* margin-top: 5%; recording navbar ui issue came from here */
}

.koolio-recording-animation {
  width: 8%;
}

.animate-play {
  animation: play 1s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
  will-change: transform;
}

.recording-duration {
  opacity: 0.85;
  display: flex;
  font-weight: bold;
  justify-content: center;
  color: #c4c4c4;
}

.global-waveform-container {
  display: flex;
  background-color: black;
  margin-top: 1%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.global-waveform-startTime,
.global-waveform-endTime {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.waveform-recording-global {
  height: 10vh;
  /* flex:70; */
  width: 65vw;
  padding: 1%;
  /* overflow: hidden; */
}

.wavesurfer-recording-div {
  overflow: auto hidden;
  scrollbar-width: thin;
  scrollbar-color: #b4b4b4 #181818;
  margin: auto;
  width: 60vw;
}
.waveurfer-recording-parentDiv {
  border-right: 2px solid #e2522b;
  border-left: 2px solid #e2522b;
  height: 45vh;
  width: 60vw;
  margin: auto;
  display: flex;
}

.global-waveformContainer {
  width: 100vw;
}

.recorder-icon {
  height: 1.5vw;
  width: 1.5vw;
  color: white;
  cursor: pointer;
  transition: color 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #e2522b;
  }

  &.active {
    color: blueviolet;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
