/* koolio-landing */
#landing-screen {
  all: unset;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: #181818;
}

/* koolio-logo */
/* .koolio-logo {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 3vh;
} */

#koolio-logo {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /* width: 12rem; */
  width: calc((210 / 1440) * 100vw);
  margin: 0;
  margin-top: 14.1321044546851vh;
}

@media screen and (min-width: 1440px) {
  #koolio-logo {
    margin-top: 14.5vh;
  }
}

@media screen and (max-width: 768px) {
  #koolio-logo {
    margin-top: 15.25vh;
  }
}

/* taglines */
.landing-taglines {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 40%;
  margin: auto;
  opacity: 0;
}

.landing-taglines {
  align-items: center;
  justify-content: space-between !important;
  /* background-color: red; */
}

.landing-taglines > img {
  user-select: none;
  margin: 0 1rem;
  /* margin-top: 0.8rem; */
  /* padding-left: 1.6rem;
    padding-right: 1.6rem; */
}

.tag1 {
  position: relative;
}

.tag2 {
  /* padding-left: 1.6rem; */
  position: relative;
}

.tag3 {
  /* padding-left: 1.6rem; */
  position: relative;
}

/* koolio-space */

.koolio-space {
  position: absolute;
  left: 0;
  right: 0;
  top: 24%;
  margin: auto;
}

.landing-koolio-text {
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 500;
  height: 8.35vh;
}

.koolio-space-text {
  /* font-size: 1.1rem; */
  /* font-size: clamp(18px, 1.5vw, 37px); */
  font-size: calc((20 / 1440) * 100vw);
  color: #8a2be2;
  padding: 0%;
}

.koolio-user {
  position: fixed;
  /* left: 46.25vw; */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  opacity: 0.9;
  letter-spacing: 6.5%;
  font-size: 1.1111127379209371vw;
  font-weight: 400;
  text-transform: capitalize;
}

/* koolio project */

.project-titles {
  height: 49.4vh;
  width: auto;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: none;
  anchor-name: --project-titles;
  scroll-snap-type: y mandatory;
}

.project-titles::-webkit-scrollbar {
  display: none;
}

.koolio-workspace {
  display: flex;
  justify-content: center;
}

/* individual project title */
#koolio-project {
  margin-top: 2.6vh;
  /* cursor: pointer; */
  opacity: 0.8 !important;
  z-index: 1;
  transition: opacity 0.3s;
}

#koolio-project:hover {
  opacity: 1 !important;
}

#koolio-project:focus {
  outline: none;
}

.koolio-project-titles::after {
  content: "\A";
  white-space: pre;
}

/* .koolio-project-titles {
    font-size: 1.25rem;
} */

.koolio-project-duration {
  /* font-size: 0.875rem; */
  /* font-size: clamp(13px, 1vw, 29px); */
  /* font-size: 0.972225475841874vw; */
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  z-index: 2001;
  color: white;
  margin: 0% 0 1%;
  border: 1px solid transparent;
}

.koolio-project-title {
  display: flex;
  justify-content: center;
}

.close-koolio-project {
  display: flex;
  justify-content: center;
}

.close-koolio-project:hover {
  color: #8a2be2;
}

.add-btn {
  position: fixed;
  bottom: 2.2222222222222223vh;
  cursor: pointer;
  height: 1.4572620790629573vw;
  width: 1.4572620790629573vw;
  transition: transform 0.3s;
}

a {
  text-decoration: none;
  color: aliceblue;
}

/* a:hover {
    color: aliceblue;
} */

.ldBar {
  height: 12px !important;
  color: #8a2be2;
}

.status,
.setting-up {
  position: absolute;
  /* min-width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45%;
}

.images {
  margin-top: 80% !important;
}

/* UPLOAD RECORD */

.record,
.upload {
  height: max-content;
  width: max-content;
  cursor: pointer;
}

.record {
  margin-bottom: 5.417276720351391vw;
}

.record img {
  height: 2.167598828696925vw;
  width: 4.860219619326501vw;
}

.upload img {
  height: 3.9005270863836015vw;
  width: 3.6797657393850653vw;
}

.upload-project {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.landing-user {
  margin-bottom: 25px;
}
