.custom-navbar {
  background-color: #181818 !important;
  display: flex;
  position: fixed;
  left: 0;
  max-width: 100%;
  font-family: "avenir" !important;
}

.more-optionsicon {
  /* position: relative;
    top: 1.1vh;
    margin-right: 0.7vw;
    margin-left:1.7vw; */
  position: fixed;
  left: 2%;
  top: 3%;
  height: calc((15 / 1440) * 100vw);
  width: calc((27 / 1440) * 100vw);
}

.header-koolio {
  position: fixed;
  left: 4.7%;
  top: 3%;
}
/* toggle switch style*/
.switch {
  position: relative;
  top: 2.5vh;
  display: inline-block;
  margin-left: 2.5vw;
  width: 2.1rem;
  /* height:2.1rem; */
  /* height: 1.1rem; */
}

#more-options-icon {
  height: 2vw;
  width: 2vw;
  color: white;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #e2522b;
  }

  &:focus {
    outline: none;
  }
}

.koolio-header-img {
  width: calc((144 / 1440) * 100vw);
  cursor: pointer;
}

.switch input {
  display: none;
}

.toggle {
  position: fixed;
  cursor: pointer;
  top: 3.9%;
  left: 17%;
  right: 0;
  bottom: 0;
  height: calc((21 / 1440) * 100vw);
  width: calc((38 / 1440) * 100vw);
  background-color: #1d0b22;
  border: 0.1rem solid rgb(250, 249, 250);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0;
  border-radius: 100vw;
  font-weight: 100;
}

.toggle:before {
  position: absolute;
  content: "";
  top: 50%;
  left: -1%;
  transform: translateY(-50%);
  height: calc((20 / 1440) * 100vw);
  width: calc((20 / 1440) * 100vw);
  /* background-color: blueviolet; */
  background: blueviolet; /* Backup */
  /* background: linear-gradient(0deg, #e2522b 0%, #8a2be2 100%); */
  border: 0.1rem solid rgb(250, 249, 250);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 100%;
}

input:checked + .toggle:before {
  -webkit-transform: translate(81%, -50%);
  -ms-transform: translate(81%, -50%);
  transform: translate(86%, -50%);
  background: #e2522b;
}

.on {
  display: none;
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 25%;
  font-size: calc((9 / 1440) * 100vw);
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 74%;
  font-size: calc((9 / 1440) * 100vw);
}

.toggle-wrapper {
  margin-left: 2.5vw;
  background-color: #e2522b;
  width: 1rem;
  height: 1rem;
}

.on {
  display: block;
}

input:checked + .toggle .off {
  display: block;
}

.circle-badge {
  height: 1.3997395833333333vw;
  width: 1.3997395833333333vw;
  display: inline-block;
  position: relative;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.circle-badge > span {
  color: #c4c4c4;
  font-size: 1vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
}

.header-project-name {
  min-width: fit-content;
  position: fixed;
  left: 50%;
  top: 3%;
  transform: translateX(-50%);
  overflow-y: visible;
  white-space: nowrap;
  center {
    .project-name {
      position: relative;
    }
  }
}

.project-name {
  border-bottom: 1px solid #e2522b;
  font-weight: 600;
  font-size: calc((22 / 100) * 8vw);
  color: #fff;
}

.project-name:focus {
  outline: none;
}

#projectTitle-edit-copy {
  padding-top: 3%;
  width: fit-content;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  opacity: 0;
  gap: 14px;
  transition: all 0.3s;
}

.header-project-name:hover #projectTitle-edit-copy,
#projectTitle-edit-copy:hover {
  opacity: 1;
}

#projectTitle-edit,
#projectTitle-copy {
  /* height: 15px; */
  height: calc((16 / 1440) * 100vw);
  cursor: pointer;
}

#right .d-inline-flex {
  justify-content: flex-end;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.user-profile-navbar {
  height: 2vw;
  width: 2vw;
  color: white;
  cursor: pointer;
  position: fixed;
  right: 7%;
  top: 3.2%;
  transition: color 0.3s;

  &:hover {
    color: #e2522b;
  }

  &:focus {
    outline: none;
  }
}

.close-workspace {
  height: 2vw;
  width: 2vw;
  color: white;
  cursor: pointer;
  position: fixed;
  top: 3.2%;
  right: 3%;
  transition: color 0.3s;

  &:hover {
    color: #e2522b;
  }

  &:focus {
    outline: none;
  }
}

.online-shareholder-profile-border {
  position: relative;
  width: calc((30 / 1440) * 100vw);
  border: 2px solid rgb(226, 82, 43, 0.7);
  border-radius: 50%;
  /* margin-left: -55px !important; */
  margin-left: -10% !important;
  cursor: pointer;
}

.offline-shareholder-profile-border {
  position: relative;
  width: calc((30 / 1440) * 100vw);
  border: 2px solid #a5a5a5;
  border-radius: 50%;
  /* margin-left: -55px !important; */
  margin-left: -10% !important;
  cursor: pointer;
}
.avatars {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-left: 70%;
}

.help-button-navbar {
  height: 2vw;
  width: 2vw;
  color: white;
  cursor: pointer;
  position: fixed;
  top: 3.2%;
  left: 21.5%;
  transition: color 0.3s;

  &:hover {
    color: #e2522b;
  }

  &:focus {
    outline: none;
  }
}
