#side-drawer {
  height: 98.78%;
  width: 21vw;
  left: -500%;
  background: linear-gradient(to right, #212121, #181818 100%);
  background-position: 100%;
  background-size: 125%;
  z-index: 10;
  overflow: hidden;
  transition: left 0.5s;
  padding-right: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.side-drawer-search {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #c4c4c4;
  border-radius: 0.52vw;
  text-align: center;
  color: #c4c4c4;
  width: 11vw;
  font-size: 1.041vw;
}

.add-speaker-container {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #c4c4c4;
  border-radius: 0.52vw;
  text-align: center;
  color: #c4c4c4;
  cursor: pointer;
  width: 2.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.041vw;
}

#drawer {
  height: 20vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 15vw;
  color: blueviolet !important;
  text-align: center;

  div {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
}

#sfx-short {
  font-size: 1.041vw;
}

#drawer::-webkit-scrollbar {
  width: 4px;
  border-radius: 20px;
  display: block !important;
}

#drawer::-webkit-scrollbar-track {
  background: transparent;
  background-color: black !important;
  border-radius: 20px;
}

#drawer::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 4px;
  background-color: whitesmoke;
  border-radius: 20px;
}

#drawer::-webkit-scrollbar-thumb:hover {
  background: blueviolet;
}

.contentEditableInputSpeaker,
.contentEditableInputSfx {
  color: blueviolet;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-top: 0.7rem;
  font-size: 1.041vw;
}

/* Autofill styles */
.contentEditableInputSpeaker:-webkit-autofill,
.contentEditableInputSfx:-webkit-autofill {
  background: none !important;
  color: blueviolet !important;
  box-shadow: 0 0 0px 1000px none inset !important; /* Prevents autofill overlay */
}

/* Optional transitions (optional for smooth visuals) */
.contentEditableInputSpeaker:-webkit-autofill,
.contentEditableInputSfx:-webkit-autofill {
  transition:
    background-color 5000s ease-in-out 0s,
    color 5000s ease-in-out 0s;
}

.contentEditableInputSpeaker {
  text-align: center;
  width: 11.09375vw;
}

.contentEditableInputSfx {
  width: 8vw !important;
}
