.card-body-cus ul {
  list-style: initial;
  text-align: left;
  /*padding: 0 10%;*/
}
.card-body-cus ul li {
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 7%;
}
#trial-month-tagline {
  color: #e2522b;
  word-spacing: 0.1em;
  letter-spacing: 0.02em;
  text-align: center;
}

/* .pricing-modal h1 {
    font-size: calc(1rem + 0.5vw);
}

.pricing-modal h4 {
    font-size: calc(0.75rem + 0.4vw);
    overflow-wrap: normal;
} 

.pricing-modal .card-header {
    background-color: var(--var-color-primary);
    color: var(--var-color-text);
    padding: 4% 0%;
}
.pricing-modal .card-header h4{
    font-size: larger;
}

.pricing-modal .card-body-cus {
    background-color: var(--var-color-background);
    padding: 10% 5%;
}

.pricing-modal .card-body-cus ul {
    list-style: initial;
    text-align: left;
    /*padding: 0 10%;
}
.card-body-cus ul li{
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left:7%;
}

#promocode {
    margin-top: 8%;
}

#promocode input {
    background-color: var(--var-color-background-dark);
    color: var(--var-color-text);
    border-radius: 3vw;
    width: 63%;
    border: 1px solid #c4c4c4;
    opacity: 0.8;
    text-align: center;
}

#promocode button {
    border-radius: 3vw;
    width: 30%;
    margin-top: 0%;
    margin-left: 1vw; 
    border: 1px solid #c4c4c4;
    opacity: 0.8;
}

/* #promocode button:hover {
    background-color: var(--var-color-secondary);
} 

#trial-month-tagline {
    color: var(--var-color-secondary);
    word-spacing: 0.1em;
    letter-spacing: 0.02em;
}

.card-body-cus button {
    margin-top: 6%;
    padding: 2% 0%;
}

.card-body-cus p{
    margin-top: 6%;
    margin-bottom: 0%;
    font-size: 1 em;
    font-weight: bold;
}

.pricing-modal .card {
    background: none;
    /* border: 1px solid var(--var-color-background); 
    border-radius: 3vw;
}

.pricing-modal .card-title {
    overflow-wrap: initial;
}

.subscription-modal {
    inset: 0;
    margin: auto;
    position: relative;
    transform: unset;
}

body:has(.subscription-modal) {
    overflow-y: hidden;
}

.half-modals:has(.subscription-modal) {
    overflow-y: auto;
}
 */
