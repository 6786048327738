.half-modals-content {
  background-color: #0c0c0c !important;
  opacity: 0.98;
  z-index: 1011;
}
.half-modals-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.half-modals-speaker {
  position: relative;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  color: blueviolet;
}
.half-modals-actions button,
.half-modals-actions-inputs button {
  background: #0c0c0c;
  border-radius: 2vw;
  color: #c4c4c4;
  opacity: 0.8;
}

.half-modals-actions-inputs button {
  border-radius: 0.7vw;
}

.half-modals-actions button:hover,
.half-modals-action-inputs button:hover {
  background: #e2522b;
  opacity: 1;
  color: #ffffff;
}
.half-modals-action-button-container .half-modals-action-button {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0px 2vw;
}

.nestedFileImportSfxModal {
  /* height: 25vh; */
  width: 50vw;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 50%;
  height: 10px;
  cursor: pointer;
  border: 1px solid #1d0b22;
  border-radius: 999px;
  margin-top: 8px;
  -webkit-transition: 0.2s;
  background-color: rgb(47, 47, 47);
  outline: none;
  opacity: 0.7;
  accent-color: #e2522b;
  transition: opacity 0.2s;
}

.slider:hover {
  filter: brightness(1.05);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #e2522b;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #e2522b;
  cursor: pointer;
  border-radius: 50%;
}

#nestedImportForSfx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.half-modals-speaker-content {
  flex: 70;
  display: flex;
  margin-left: 20px;
}

.half-modals-action-button {
  width: 15% !important;
  border: 1px solid !important;
  /* margin-top: 1.7vw; */
}
