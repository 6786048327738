.grecaptcha-badge {
  position: fixed;
  right: 0;
}

.line-height-input {
  margin-top: calc((37.5 / 900) * 100 * 100vh / 100);
}

.line-height-input-sign {
  margin-top: calc((41.7 / 900) * 100 * 100vh / 100);
}
.line-height-input-alt {
  margin-top: calc((21.85 / 900) * 100 * 100vh / 100);
}
.btn-padding {
  padding: 0.390625vw !important;
}

/* .password-hidden {
  position: absolute;
  transform: translate(-1.5vw, 4vh);
} */
.text-large {
  font-size: calc((((15.5 / 1440) * 100) * 100vw) / 100);
  padding: calc((((6 / 990) * 100) * 100vh) / 100);
}
.text-medium {
  font-size: calc((((15 / 1440) * 100) * 100vw) / 100);
}
.forgot-password {
  padding: calc((((9 / 990) * 100) * 100vh) / 100);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.signup-opt {
  top: calc((((15 / 990) * 100) * 100vh) / 100);
}
.sendOtpTimer {
  top: calc((((21.85 / 990) * 100) * 100vh) / 100);
  margin-left: calc((((10 / 1440) * 100) * 100vw) / 100);
  font-size: calc((((16 / 1440) * 100) * 100vw) / 100);
}

#terms-checkbox {
  width: calc((((15.9 / 1440) * 100) * 100vw) / 100);
  margin-top: calc((((-4.25 / 1440) * 100) * 100vw) / 100);
  margin-right: calc((((4.25 / 1440) * 100) * 100vw) / 100);
}

.terms-modal {
  height: calc((((600 / 990) * 100) * 100vh) / 100);
  width: calc((((600 / 1440) * 100) * 100vw) / 100);
}
.terms-header-text {
  font-size: calc((((20 / 1440) * 100) * 100vw) / 100);
  padding: calc((((22 / 1440) * 100) * 100vw) / 100);
}

.content-header {
  font-size: calc((((16 / 1440) * 100) * 100vw) / 100);
}

.content-data {
  font-size: calc((((14 / 1440) * 100) * 100vw) / 100);
}
.terms-action-button {
  font-size: calc((((14 / 1440) * 100) * 100vw) / 100);
  border: 1px solid #c4c4c4;
  border-radius: 2vw;
}
.terms-content {
  height: calc((((395 / 1440) * 100) * 100vw) / 100);
}
.terms-content-data {
  padding-left: calc((((22 / 1440) * 100) * 100vw) / 100);
  padding-right: calc((((22 / 1440) * 100) * 100vw) / 100);
  padding-top: calc((((22 / 1440) * 100) * 100vw) / 100);
}
.terms-modal-options {
  padding: calc((((16 / 1440) * 100) * 100vw) / 100);
}
.terms-decline {
  margin-right: calc((((12 / 1440) * 100) * 100vw) / 100);
}
.action-project-button {
  width: calc((((60 / 1440) * 100) * 100vw) / 100);
  padding: calc((((5 / 1440) * 100) * 100vw) / 100);
}

.koolio-space {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

#koolio-login-logo {
  height: 3.167320644216691vw;
  width: 14.582942898975109vw;
  position: fixed;
  top: 0;
  left: 0;
  margin: unset;
  padding: unset;
  margin-top: 6.736111111111111vw;
  margin-left: 22.25%;
}

#form {
  position: fixed;
  position-anchor: --koolio-mic-logo;
  top: anchor(center);
  left: 0;
  margin: unset;
  margin-left: 0.36603221083455345vw;
}

@supports not (position-anchor: --koolio-mic-logo) {
  #form {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 0.36603221083455345vw;
    margin-top: calc((191 / 1440) * 100vw);
  }

  @media screen and (width < 1024px) {
    #form {
      margin-top: calc((253 / 1080) * 100vh);
    }
  }

  @media screen and (width >= 1920px) {
    #form {
      margin-top: calc((210 / 1440) * 100vw);
    }
  }
}

@media screen and (width < 1024px) {
  #koolio-login-logo {
    margin-top: calc((161 / 1080) * 100vh);
  }
}

@media screen and (width >= 1920px) {
  #koolio-login-logo {
    margin-top: calc((118 / 1440) * 100vw);
  }
}

#checkbox-input {
  width: fit-content;
  margin-right: 5px;
}

.inputStyle {
  width: 25vw;
  background-color: #181818;
  outline: none;
  border: none;
  color: #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  font-weight: 600;
  opacity: 0.9;
}
.inputStyle input {
  background-color: unset;
  background-color: #181818;
  /* font-weight: 900; */
}
.inputStyle input::placeholder {
  /* font-weight: 900; */
  background-color: #181818 !important;
  opacity: 0.9;
}
#lfpwd {
  margin-left: -5px;
}
#sipwd {
  margin-left: -5px;
}
#sicpwd {
  margin-left: -5px;
}
.disable-btn {
  opacity: 0.5;
  cursor: not-allowed;
  padding: calc((((5.5 / 1440) * 100) * 100vw) / 100);
}
.err-message {
  color: blueviolet;
  font-size: 1.5vw !important;
  /* color: #e2522b; */
}
.auth-screen {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}

button.disabled {
  background-color: #c4c4c4 !important;
  color: #e2522b !important;
}

.btn-active {
  background: #e2522b !important;
  opacity: 1 !important;
  color: #ffffff !important;
}

a {
  cursor: pointer;
  color: #e2522b !important;
}

.sep {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 2%;
}

.signup,
.login {
  color: #c4c4c4;
  cursor: pointer;
}
.declBtn:hover,
.accBtn:hover {
  background-color: #e2522b;
}
.signup:hover,
.login:hover {
  color: #e2522b;
}

/* INVITE ONLY */
.koolio-textarea {
  display: block;
  width: 25vw;
  overflow: hidden;
  resize: none;
  text-align: left;
}

.koolio-textarea[contenteditable]:empty::before {
  content: "REASON TO USE KOOLIO.AI?";
  color: #c4c4c4;
  opacity: 0.4;
  font-weight: 500;
}

/* .auth-input > input, */
.koolio-textarea {
  background-color: #181818 !important;
  border: none;
  outline: none;
  text-decoration: none;
  color: #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding: 1%;
  margin-top: 1%;
  margin-bottom: 2%;
}

/* .auth-input input, */
.ggl-or {
  width: 25vw;
}

/* .auth-input input::placeholder {
  color: #c4c4c4;
  opacity: 0.5;
  /* font-size: 0.81rem; */
/* letter-spacing: 0.04rem; 
} */

.resend-otp {
  text-decoration: none;
  color: #c4c4c4 !important;
  opacity: 0.6;
}

.forgot-password {
  /* font-size: 0.91rem; */
  text-decoration: none;
  color: #c4c4c4 !important;
  opacity: 0.6;
  width: fit-content;
  /* margin-bottom: 2%; */
}

.forgot-password:hover {
  opacity: 0.9;
  color: #e2522b !important;
}

.google-button {
  width: 25vw;
  background: rgb(138, 43, 226);
  /* font-size: 0.9rem; */
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  color: #ffffff;
  padding: 0.8vh;
  border-radius: 7px;
  font-weight: 700;
}

.google-button:focus {
  outline: none;
}

#buttonDiv img {
  height: auto;
  width: 2.4089458272327966vw;
  margin-left: 19%;
}

@media screen and (width < 1920px) {
  #buttonDiv img {
    margin-left: 15%;
  }
}

.auth-button {
  height: 2.5256222547584186vw;
  width: 25vw;
  background: rgb(138, 43, 226);
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  color: #ffffff;
  padding: 5px;
  border-radius: 50px;
}

.auth-button:focus {
  outline: none;
}

.auth-button:hover {
  background: rgb(138, 43, 226) !important;
  color: #ffffff !important;
}
.resendOtp-button {
  position: relative;
  color: #66676c !important;
  top: calc((((21.85 / 990) * 100) * 100vh) / 100);
  font-size: calc((((16 / 1440) * 100) * 100vw) / 100);
  text-decoration: none;
}

.resendOtp-button:hover {
  color: #e2522b;
}

.signup-opt {
  color: #c4c4c4 !important;
  opacity: 0.6;
  /* margin-top: 2rem; */
}

.sign-up {
  color: #e2522b !important;
  opacity: 1 !important;
  cursor: pointer;
  /* margin-top: 2rem; */
}

.terms-modal {
  position: absolute;
  background: rgb(12, 12, 12, 0.97);
  /* height: 450vh;
  width: 600vw; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin-left: 50% !important;
  transform: translateX(-50%) !important;
}

.modal-content {
  background-color: rgb(12, 12, 12, 0.97);
  margin: auto;
  max-height: 100%;
  text-align: start;
  max-width: 100%;
}
#terms-checkbox {
  cursor: pointer;
}

.terms-modal > .modal-content {
  background-color: rgb(12, 12, 12, 0.97);
  text-align: unset;
  margin: unset;
  max-width: unset;
}

.terms-modal > .modal-content > .modal-header {
  padding: 0px;
}

.terms-header-text {
  color: #c4c4c4;
  text-align: start;
}

.terms-content {
  padding: 1%;
  overflow-y: auto;
  text-align: start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.terms-content::-webkit-scrollbar {
  display: none;
}

.content-header {
  color: #c4c4c4;
  opacity: 0.9;
}

.content-data {
  color: #c4c4c4;
  /* font-size: 14px; */
  opacity: 0.65;
}
/* .action-project button {
  background: #0c0c0c;
  border: 1px solid #c4c4c4 !important;
  border-radius: 2vw;
  color: #c4c4c4;
}

.action-project button:hover {
  background: #e2522b;
  opacity: 1;
  color: #ffffff;
} */

.terms-modal-options {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  /* align-items: center; */
  justify-content: flex-end;
  border-top: 1px solid #e2522b;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

#view-password-cont > img,
#view-password-cont-si > img,
#view-password-cont-sic > img {
  opacity: 0.5;
  cursor: pointer;
}

#view-password-cont > img,
#view-password-cont-si > img,
#view-password-cont-sic > img {
  width: calc((((18 / 1440) * 100) * 100vw) / 100);
  margin-left: calc((((-25 / 1440) * 100) * 100vw) / 100);
}

.image {
  height: 34.87371888726208vw;
  width: 40.41566617862372vw;
  position: fixed;
  top: 50%;
  right: 6.734992679355783vw;
  transform: translateY(-50%);
  anchor-name: --koolio-mic-logo;
}

@media (min-width: 0px) and (max-width: 576px) {
  /*  .image {
    bottom: 0px;
  }*/
}
@media (min-width: 1149px) and (max-width: 1548px) {
  /* .google-button {
    /* width: 45vw !important; 
    font-size: 0.8rem;
  } */
  /*  .image {
    bottom: 48px;
    right: 80px;
  }*/
}
@media (min-width: 741px) and (max-width: 1148px) {
  /* .google-button {
    /* width: 45vw !important; 
    font-size: 0.5rem;
  } */
  /*  .image {
    right: 60px;
  }*/
}
@media (min-width: 577px) and (max-width: 740px) {
  .koolio-logo {
    align-items: center;
    justify-content: center;
  }
  #koolio-logo {
    width: 20vw;
    height: 4vh;
  }
  /* .google-button {
    /* width: 45vw !important; 
    font-size: 0.5rem;
  } */
  /*  .image {
    right: 45px;
  }*/
}
/* @media only screen and (min-width: 430px) and (max-width: 575px) {
  
} */
@media (min-width: 0px) and (max-width: 576px) {
  /* #koolio-login-logo {
    width: 45vw !important;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    margin-left: 12.5%;
  } */
  /* .logo {
    width: 45vw !important;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 25%;
    right: 35%;
    margin: auto;
  } */
  /* .koolio-user {
    font-size: 2.5vw !important;
  } */
  .image {
    top: 96.25%;
    left: 50%;
    transform: translateX(-50%);
  }

  .auth-input input {
    width: 45vw !important;
    /*margin-top: 10vw !important;*/
  }
  .ggl-or,
  .google-button {
    width: 45vw !important;
    padding: 0vh;
  }
  #login-form input,
  #request-access-form input,
  #forgot-pwd-form input {
    font-size: 2.5vw !important;
    padding: 0.5vw !important;
  }

  .koolio-textarea {
    width: 45vw !important;
    font-size: 2.5vw !important;
  }
  .forgot-password,
  #cance-password {
    font-size: 0.8rem;
    color: #c4c4c4 !important;
    opacity: 0.6;
  }
  .auth-button {
    width: 45vw !important;
    font-size: 2.5vw !important;
    padding: 0.5vw !important;
  }
  .signup-msg,
  .login-msg {
    /* margin-top: 1%; */
    top: 5vw !important;
  }
  .signup-msg span,
  .login-msg span {
    font-size: 2.5vw !important;
  }
}
