.tooltip-container {
  background-color: #181818;
  overflow: hidden;
  overflow-wrap: normal;
  word-wrap: normal;
  width: max-content;
  max-width: 33.333333333333336vw;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 0.25vw 0.5vw;
}

.tooltip-title {
  padding: 12px 12px 6px 12px;
  font-size: 1.25vw;
  font-weight: 800;
  color: #e2522b;
  margin-bottom: 0.8333333333333334vw;
  text-align: center;
}

.tooltip-content {
  padding: 0 12px;
  font-size: 0.9375vw;
  font-weight: 500;
  color: white;
  line-height: 1.3;
  margin-bottom: 1.6666666666666667vw; /* equivalent to Tailwind's mb-8 */
}

.tooltip-footer {
  background-color: #181818;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px 16px;
  gap: 0.8333333333333334vw;
}

.tooltip-back-button {
  background-color: transparent;
  color: blueviolet;
  padding: 6px 32px;
  font-size: 0.9375vw;
  border: none;
  border-radius: 1.5625vw;
}

.tooltip-skip-button {
  background-color: #1b1a1a;
  color: white;
  padding: 6px 32px;
  font-size: 0.9375vw;
  border: none;
  border-radius: 1.5625vw;
  margin-right: auto;
}

.tooltip-step-counter {
  display: none;
  font-size: 12.8px;
  color: white;
  font-weight: 500;
}

.tooltip-next-button {
  cursor: pointer;
  border: none;
  font-size: 0.9375vw;
  color: white;
  background-color: #5e1c9c;
  border-radius: 1.5625vw;
  padding: 7px 32px;
}

.tooltip-back-button,
.tooltip-next-button,
.tooltip-skip-button {
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

/* Hover effect for both buttons */
.tooltip-back-button:hover {
  color: #e2522b;
}

.tooltip-skip-button:hover,
.tooltip-next-button:hover {
  background-color: #e2522b;
  color: white;
}
