.buzzError {
  opacity: 0.9;
  color: #9c26f6;
  size: 14px;
  display: none;
}

.api-input {
  background: none;
  color: #c4c4c4;
}
.half-modals-actions-inputs {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5208333333333334vw;
  margin-top: 3.4505208333333335vw;
  margin-bottom: 2.1484375vw;
  gap: 1.3020833333333333vw;
}
.half-modals-actions-inputs button {
  flex: 1 1 calc(33.33% - 30px) !important; /* Adjust the percentage to fit 3 buttons per row */
  margin: 0.3255208333333333vw; /* Adjust margin as needed */
}

.publishModal .half-modals-actions-inputs button {
  height: max-content !important;
  padding: 1.3671875vw 2.7994791666666665vw !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.publishModal .half-modals-actions-inputs img {
  height: 1.7578125vw;
  width: 1.7578125vw;
  object-fit: contain;
}

.individualPublishModal .half-modals-content {
  /* height: 23.290169270833335vw !important; */
  height: max-content !important;
  width: 52.5vw !important;
  padding: 2.0833333333333335vw !important;
}

.individualPublishModal .half-modals-header {
  width: 100% !important;
}

.individualPublishModal .half-modals-input-field {
  height: max-content !important;
  margin-top: 2.2135416666666665vw !important;
  margin-bottom: 1.4322916666666667vw !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.individualPublishModal .half-modals-input-field input {
  display: block;
  height: max-content;
  padding: 0.3255208333333333vw !important;
  font-size: 1vw !important;
}

.individualPublishModal .half-modals-input-field input:not(:nth-child(1)) {
  margin-top: 1.3671875vw;
}

.individualPublishModal #publish-api-logout {
  height: max-content !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 1.8vw !important;
  margin-right: 1.55vw !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.individualPublishModal .half-modals-actions .half-modals-action-button {
  position: static;
  margin: 0 !important;
  line-height: 0%;
}

.individualPublishModal .half-modals-actions {
  margin-top: 1.8vw;
}

.individualPublishModal .suggestion {
  /* margin-top: 0.5vw; */
}

.individualPublishModal .suggestion .searchList li {
  font-size: 1vw;
}
